import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRef, useState, useEffect } from 'react';
import { Button, Typography, Box, CircularProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SearchIcon from '@mui/icons-material/Search';
import InputComponent from '../Inputs/InputComponent';
import SelectComponent from '../Inputs/SelectComponent';
import DatePickerComponent from '../Inputs/datePicker';
import styles from '../../index.module.scss';
import { createConsProject, getClients } from '../../../store/construction/constructionActions';
import { createProject, getProjectCategories } from '../../../store/projects/projectActions';
import { constructionActions } from '../../../store/construction';
import { projectActions } from '../../../store/projects';

export default function NewProject(props: any) {
  const [init, setInit] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [saving, setSaving] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();
  const [category, setCategory] = useState('');
  const [partner, setPartner] = useState('');
  const nameRef = useRef<HTMLInputElement>(null);
  const amountRef = useRef<HTMLInputElement>(null);
  const dateRef = useRef<HTMLInputElement>(null);
  const locationRef = useRef<HTMLInputElement>(null);
  const categories = useSelector((state: any) => state.project.categories);
  const clients = useSelector((state: any) => state.construction.clients);
  // const nameRef = useRef<HTMLInputElement>(null);

  var categoryValues = [];

  if (categories.length > 0) {
    categoryValues = categories.map((cat: any) => {
      return {
        name: cat.category,
        _id: cat._id,
      };
    });
  }

  const handleCategoryChange = (event: any) => {
    setCategory(event.target.value as string);
  };

  const handlePartnerChange = (event: any) => {
    setPartner(event.target.value as string);
  };

  useEffect(() => {
    if (init) {
      console.log('run everytime  ...');
      setFetching(true);
      dispatch(getClients());
      dispatch(getProjectCategories());
    }
    return () => {
      console.log('Runs excactlyyyy whenenenenenennnnnnnnnnnnnnnnnnnnnn ?');
      setInit(false);
    };
  }, [dispatch, init]);

  useEffect(() => {
    if (clients.length > 0 && categories.length > 0) {
      console.log('runiiigd everytime  ...');
      setFetching(false);
    }
  }, [dispatch, clients, categories]);

  const submit = async () => {
    dispatch(constructionActions.setFetching(true));
    setSubmitting(true);
    var data = {
      name: nameRef.current?.value,
      category,
      startDate: dateRef.current?.value,
      location: locationRef.current?.value,
      amount: amountRef.current?.value,
      client: partner,
    };
    setSaving(true);
    var project = await createProject(data);
    await createConsProject(project, data);
    setSaving(false);
    props.setOpen(false);
    dispatch(projectActions.setUpdateNeeded(true));
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => props.setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={`${styles.formHeader} ${styles.center}`}>
          <Typography className={styles.formHeaderText}>NEW PROJECT</Typography>
        </DialogTitle>
        <DialogContent className={styles.formContent}>
          {fetching && (
            <Box className={styles.loadingContainer}>
              <CircularProgress />
            </Box>
          )}
          {!fetching && (
            <Box>
              <InputComponent label="Name" reference={nameRef} icon={<SearchIcon />} placeholder="Enter Project Name" />
              {categoryValues.length > 0 && (
                <SelectComponent
                  label="Project Category"
                  value={category}
                  values={categoryValues}
                  setValue={setCategory}
                  handleChange={handleCategoryChange}
                />
              )}
              <InputComponent
                label="PO Amount"
                reference={amountRef}
                icon={<SearchIcon />}
                placeholder="Enter PO Total Amount"
              />
              <DatePickerComponent
                label="Start Date"
                icon={<SearchIcon />}
                reference={dateRef}
                placeholder="Provice Start Date"
              />
              {/* <InputComponent
                        label="Date"
                        icon={<SearchIcon />}
                        placeholder="Provice Start Date"
                    /> */}
              <InputComponent
                label="Location"
                reference={locationRef}
                icon={<SearchIcon />}
                placeholder="Add Project Location"
              />
              {clients.length > 0 && (
                <SelectComponent label="Client" value={partner} values={clients} handleChange={handlePartnerChange} />
              )}
            </Box>
          )}
        </DialogContent>
        <DialogActions className={styles.center}>
          {!fetching && (
            <Box className={styles.buttonContainer}>
              <Button onClick={() => submit()} variant="contained" className={styles.formButton}>
                {!saving && 'Save'}
                {saving && <CircularProgress size={30} className={styles.circularProgress} />}
              </Button>
            </Box>
          )}
        </DialogActions>
      </Dialog>
    </div>
  );
}
