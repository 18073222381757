import { Grid, Typography, Box, Divider, Avatar, Button, TextField } from '@mui/material';
import styles from './index.module.scss';
import { useSelector } from 'react-redux';
import HomeLayout from '../../components/layout/HomeLayout';
import user from '../../assets/images/user.png';

const UserAccount = (props: any) => {
  const { user } = useSelector((state: any) => state?.auth);
  return (
    <HomeLayout select={2}>
      <Box className={styles.profileContainer}>
        <Typography className={styles.profileTitle}>Account</Typography>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Box className={styles.profilePhotoCard}>
              <Avatar
                style={{
                  height: '5rem',
                  width: '5rem',
                }}
              >
                <img className={styles.profilePicture} src={user?.photo} alt="profile" />
              </Avatar>
              <Typography className={styles.profileName}>{user?.name}</Typography>
              <Typography className={styles.profileSecondaryText}>{user?.email}</Typography>
              <Typography className={styles.profileSecondaryText}>+250 {user?.contact}</Typography>
              <Divider />
              <Divider />
              <Button className={styles.uploadImage}>Upload Image</Button>
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Box className={styles.profileLeftSection}>
              <Box className={styles.profileTopSection}>
                <Typography variant="h6">Profile</Typography>
                <Typography className={styles.profileSecondaryText}>The information can be edited</Typography>
              </Box>
              <Divider />
              <Grid className={styles.profileMainSection} container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    id="outlined-helperText"
                    label="First Name"
                    defaultValue="Eric"
                    variant="outlined"
                    className={styles.profileInput}
                  />
                  <TextField
                    id="outlined-helperText"
                    label="Phone Number"
                    defaultValue="+250788329340"
                    variant="outlined"
                    className={styles.profileInput}
                  />
                  <TextField
                    id="outlined-helperText"
                    label="Address"
                    defaultValue="KICUKIRO-KABEZA"
                    variant="outlined"
                    className={styles.profileInput}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="outlined-helperText"
                    label="Last Name"
                    defaultValue="TURATSINZE"
                    variant="outlined"
                    className={styles.profileInput}
                  />
                  <TextField
                    id="outlined-helperText"
                    label="E-mail"
                    defaultValue="turatsinzeeric43@gmail.com"
                    variant="outlined"
                    className={styles.profileInput}
                  />
                  <TextField
                    id="outlined-helperText"
                    label="Role"
                    defaultValue="admin"
                    variant="outlined"
                    className={styles.profileInput}
                  />
                </Grid>
              </Grid>
              <Divider />
              <Box className={`${styles.right} ${styles.mg1Vertical}`}>
                <Button className={styles.genericButton} variant="contained">
                  Save details
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </HomeLayout>
  );
};

export default UserAccount;
