import { Menu, MenuItem, Typography } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TableComponent from '../../../components/UI/others/TableComponent';
import { shopItemColumns, shopItemData } from '../../../interfaces/shop/shopItems';
import { projectActions } from '../../../store/projects';
import DeleteIcon from '@mui/icons-material/Delete';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import styles from '../index.module.scss';
import ConfirmModal from '../../../components/UI/others/ConfirmModal';
import { deleteItem } from '../../../store/shop/shopActions/Items';

const ItemsTable = () => {
  const [showModal, setShowModal] = useState(false);
  const token = useSelector((state: any) => state.auth.token);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(25);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [newItem, setNewItem] = useState(false);
  const [query, setQuery] = useState('');
  const projectState = useSelector((state: any) => state.project);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const shopState = useSelector((state: any) => state.shop);
  const [itemName, setItemName] = useState('');
  const [itemId, setItemId] = useState('');
  const dispatch = useDispatch();

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseAction = () => {
    handleClose();
    setShowModal(true);
  };

  const handleEditAction = () => {
    handleClose();
  };

  const handleDeleteItem = () => {
    console.log('Deleting item.........');
    dispatch(projectActions.setIsFetching(true));
    dispatch(deleteItem(itemId, token));
  };

  // const handlePageChange = (event: unknown, newPage: number) => {
  //   setPage(newPage);
  // };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
    console.log('---', newPage);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createData = (
    id: string,
    creationDate: string,
    name: string,
    manufacturer: string,
    buyingPrice: string,
    sellingPrice: string
  ): shopItemData => {
    return {
      id,
      creationDate,
      name,
      manufacturer,
      buyingPrice,
      sellingPrice,
      action: (
        <button
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setItemName(name);
            setItemId(id);
            handleClick(event);
          }}
          className={styles.actionsIcon}
        >
          •••
        </button>
      ),
    };
  };

  const rows = shopState?.shopItems?.map((item: any) =>
    createData(
      item._id,
      item.createdAt?.substring(0, 10),
      item.name,
      item?.manufacturer?.name,
      item?.buyingprice,
      item?.sellingprice
    )
  );

  return (
    <>
      {rows?.length > 0 && (
        <TableComponent
          rows={rows}
          columns={shopItemColumns}
          rowClickedHandler={() => {}}
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          handlePageChange={handleChangePage}
          handleRowsPerPageChange={handleRowsPerPageChange}
        />
      )}
      {open && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={handleEditAction}>
            <AutorenewIcon />
            <Typography>Edit</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              setShowModal(true);
            }}
          >
            <DeleteIcon />
            <Typography>Delete</Typography>
          </MenuItem>
        </Menu>
      )}
      {showModal && (
        <ConfirmModal
          open={showModal}
          onCancel={() => setShowModal(false)}
          setOpen={setShowModal}
          onConfirm={() => {
            handleDeleteItem();
          }}
          content={`Please confirm to delete ${itemName} item`}
        />
      )}
    </>
  );
};

export default ItemsTable;
