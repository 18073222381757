import React, { useEffect, useState } from 'react';
import { Typography, Box, Alert, Grid, Tab, Tabs, Modal, TextField, Stack } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import LoadingButton from '@mui/lab/LoadingButton';
import { useSelector, useDispatch } from 'react-redux';
import ConstructionLayout from '../../../components/layout/Construction';
import {
  getConsProjects,
  getStocksByProjectIdAction,
  getItemsByStockIdAction,
  getProject,
  addStockAction,
} from '../../../store/construction/constructionActions';
import { constructionActions } from '../../../store/construction';
import StockInCard from './card/StockIn';
import StockOutCard from './card/StockOut';
import { useNavigate, useParams } from 'react-router-dom';
import NewProduct from '../../../components/UI/Forms/NewProduct';
import TopActions from '../../../components/layout/TopActions';
// import NewProduct from "./NewProduct";
import styles from './index.module.scss';
import Filter from '../../../components/UI/Forms/Filter';
import DashboardTable from './DashboardTable';

import { Button, InputLabel } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
const Home = (props: any) => {
  const projectParams = useParams();
  const [newProduct, setNewProduct] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [newStock, setNewStock] = useState('');
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const constructionState = useSelector((state: any) => state.construction);
  const projectsState = useSelector((state: any) => state.project);
  const [tabValue, setTabValue] = React.useState(0);

  const tabs = [<StockInCard />, <StockOutCard />];

  const handleChangeOnTab = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };
  const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const createExportableData = (
    id: string,
    projectId: string,
    name: string,
    client: string,
    startDate: string,
    po: number,
    status: string,
    slug: string
  ): any => {
    return {
      name,
      client,
      startDate,
      po,
      status,
      slug,
    };
  };

  const data = constructionState?.projects?.map((project: any) =>
    createExportableData(
      project?._id,
      project?.project._id,
      project?.project?.name,
      project?.client?.name,
      project?.startDate.substring(0, 10),
      project?.contractAmount,
      project?.status,
      project?.project.slug
    )
  );
  const [stock, setStock] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleChange = (event: SelectChangeEvent) => {
    setStock(event.target.value);
    dispatch(getItemsByStockIdAction(event.target.value));
  };
  useEffect(() => {
    console.log(projectParams.id, '>>>');
    if (projectParams.id) {
      dispatch(getProject(projectParams?.id));
      dispatch(getStocksByProjectIdAction(projectParams?.id));
    }
    if (projectsState.updateNeeded) {
      // dispatch(constructionActions.setFetching(true));
      dispatch(getConsProjects(`page=${page}&limit=${rowsPerPage}${constructionState.filter}`));
    }
  }, [dispatch, page, constructionState.added, projectParams]);

  return (
    <>
      <ConstructionLayout select={2}>
        <Grid container spacing={0}>
          <Grid item xs={8}>
            <Box className={`${styles.center} ${styles.homeHeader} ${styles.centerSpacing}`}>
              <Typography sx={{ fontSize: '1.5rem' }}>
                STOCKS OF <span style={{ fontWeight: 'bolder' }}> {constructionState?.project?.project?.name} </span>
              </Typography>
              <FormControl sx={{ m: 1, minWidth: '30%' }}>
                <InputLabel id="demo-simple-select-helper-label">Select Stock</InputLabel>
                <Select
                  labelId="demo-select-small"
                  id="demo-select-small"
                  value={stock}
                  label="Select Stock"
                  onChange={handleChange}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {constructionState?.stock.map((st: any) => (
                    <MenuItem value={st?._id}>{st?.name}</MenuItem>
                  ))}
                </Select>
                {/* <FormHelperText>With label + helper text</FormHelperText> */}
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box className={`${styles.center} ${styles.homeHeader} ${styles.centerSpacing}`}>
              <Button
                size="large"
                variant="contained"
                startIcon={<AddIcon />}
                className={styles.newButton}
                onClick={() => handleOpen()}
              >
                Add New Stock
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item xs={8}>
            <TopActions setFilter={setShowFilter} data={data} setNew={setNewProduct} entity="Product" />
            {newProduct && <NewProduct open={newProduct} setOpen={setNewProduct} />}
            {showFilter && <Filter open={showFilter} setOpen={setShowFilter} />}
            <DashboardTable />
          </Grid>
          <Grid item xs={4}>
            <Box className={`${styles.centerTab}`}>
              <Tabs
                value={tabValue}
                onChange={handleChangeOnTab}
                variant="fullWidth"
                aria-label="disabled tabs example"
              >
                <Tab label="Stock In" />
                <Tab label="Stock Out " />
                <Tab label="Payments" />
              </Tabs>
              {tabs[tabValue]}
            </Box>
          </Grid>
        </Grid>
      </ConstructionLayout>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Create New Stock
          </Typography>

          <Stack direction="row" justifyContent="center" alignItems="space-between" spacing={2}>
            <TextField
              fullWidth
              required
              id="outlined-required"
              label="Stock Name"
              onChange={(e: any) => setNewStock(e.target.value)}
            />

            <LoadingButton
              onClick={() => {
                newStock && dispatch(addStockAction({ name: newStock, project: projectParams.id }));
                newStock && setOpen(false);
                !newStock && <Alert severity="warning">This is a warning alert — check it out!</Alert>;
              }}
              variant="contained"
              fullWidth
              loading={constructionState.added}
              startIcon={<SaveIcon />}
            >
              Save
            </LoadingButton>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};

export default Home;
