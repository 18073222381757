import axios from 'axios';
import { constructionActions } from '.';
import { SERVER_URL } from '../../utils/constants';
import { projectActions } from '../projects';
import { getStocksByProjectId, getItemsByStockId, addStock } from './constructionService';

export const getConsProjects = (query?: string) => {
  return async (dispatch: any) => {
    try {
      const res = await axios({
        method: 'GET',
        url: query
          ? `${SERVER_URL}/api/v1/project/constructionprojects?${query}`
          : `${SERVER_URL}/api/v1/project/constructionprojects`,
      });

      const projects = res.data.data.data;
      dispatch(constructionActions.setProjects({ projects }));
      dispatch(projectActions.setIsFetching(false));
      dispatch(projectActions.setUpdateNeeded(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getProject = (projectId: string) => {
  return async (dispatch: any) => {
    try {
      const res = await axios({
        method: 'GET',
        url: `${SERVER_URL}/api/v1/project/constructionprojects/${projectId}`,
      });
      dispatch(constructionActions.setProject(res.data.data.data));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getStocksByProjectIdAction = (projectId: string) => {
  return async (dispatch: any) => {
    try {
      const res = await getStocksByProjectId(projectId);
      if (res?.status === 200) dispatch(constructionActions.setStock(res?.data?.data?.data));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getItemsByStockIdAction = (stockId: string) => {
  return async (dispatch: any) => {
    try {
      const res = await getItemsByStockId(stockId);
      if (res?.status === 200) dispatch(constructionActions.setStockItems(res?.data?.data?.data));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getExpenses = (constructionId: string, query: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(constructionActions.setFetching(true));
      const res = await axios({
        method: 'GET',
        url: query
          ? `${SERVER_URL}/api/v1/construction/expenses?project=${constructionId}&${query}`
          : `${SERVER_URL}/api/v1/expenses?project=${constructionId}`,
      });
      dispatch(constructionActions.setExpenses({ expenses: res.data.data.data }));
      dispatch(projectActions.setUpdateNeeded(false));
      dispatch(constructionActions.setFetching(false));
    } catch (err) {
      console.log('error: ', err);
    }
  };
};

export const addClient = (data: any) => {
  return async (dispatch: any) => {
    try {
      const res = await axios({
        method: 'POST',
        url: `${SERVER_URL}/api/v1/construction/clients`,
        data,
      });
      dispatch(projectActions.setIsFetching(false));
      dispatch(constructionActions.setAdded(true));
      dispatch(projectActions.setUpdateNeeded(true));
    } catch (err) {
      console.log(err);
    }
  };
};

export const addStockAction = (data: any) => {
  return async (dispatch: any) => {
    try {
      dispatch(constructionActions.setAdded(true));
      dispatch(constructionActions.setFetching(true));
      const res = await addStock(data);
      dispatch(constructionActions.setFetching(false));
      dispatch(constructionActions.setAdded(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getClients = () => {
  return async (dispatch: any) => {
    try {
      const res = await axios({
        method: 'GET',
        url: `${SERVER_URL}/api/v1/construction/clients`,
      });
      dispatch(constructionActions.setPartners({ clients: res.data.data.data }));
      dispatch(projectActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const createConsProject = async (project: string, data: any) => {
  try {
    await axios({
      method: 'POST',
      url: `${SERVER_URL}/api/v1/project/constructionprojects`,
      data: {
        project,
        contractAmount: data.amount,
        location: data.location,
        client: data.client,
      },
    });
  } catch (err) {
    console.log(err);
  }
};

export const createExpense = async (data: any) => {
  try {
    await axios({
      method: 'POST',
      url: `${SERVER_URL}/api/v1/construction/expenses`,
      data,
    });
  } catch (err: any) {
    console.log(err.response.data.message);
  }
};

export const updateExpense = async (id:String,data: any) => {
  try {
    await axios({
      method: 'PATCH',
      url: `${SERVER_URL}/api/v1/construction/expenses/${id}`,
      data,
    });
  } catch (err: any) {
    console.log(err.response.data.message);
  }
};

export const deleteExpense = async (id:String) => {
  try {
    await axios({
      method: 'DELETE',
      url: `${SERVER_URL}/api/v1/construction/expenses/${id}`,
    });
  } catch (err: any) {
    console.log(err.response.data.message);
  }
};

export const getPayroll = (query: String, query2: String) => {
  return async (dispatch: any) => {
    try {
      var response = await axios({
        method: 'GET',
        url: `${SERVER_URL}/api/v1/project/constructionprojects${query}`,
      });
      var employees = response.data.data.data[0].employees;
      for (var employee in employees) {
        var emp = employees[employee];
        var payroll = await axios({
          method: 'GET',
          url: `${SERVER_URL}/api/v1/construction/payroll?employee=${emp}&sort=date&${query2}`,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const addPurchaseOrder = () => {
  return async (dispatch: any) => {
    try {
      var response = await axios({
        method: 'GET',
        url: `${SERVER_URL}/api/v1/project/constructionprojects}`,
      });
    } catch (err) {
      console.log(err);
    }
  };
};

export const getDocuments = (projectId: String) => {
  return async (dispatch: any) => {
    try {
      dispatch(projectActions.setIsFetching(false));
      const pos = await axios({
        method: 'GET',
        url: `${SERVER_URL}/api/v1/construction/document?project=${projectId}&isImage=false`,
      });
      dispatch(constructionActions.setPurchaseOrders(pos.data.data.data));
      dispatch(projectActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getImages = (projectId: String) => {
  return async (dispatch: any) => {
    try {
      dispatch(projectActions.setIsFetching(false));
      const pos = await axios({
        method: 'GET',
        url: `${SERVER_URL}/api/v1/construction/document?project=${projectId}&isImage=true`,
      });
      dispatch(constructionActions.setImages(pos.data.data.data));
      dispatch(projectActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

// export const getShopSales = (shopId: string) => {
//     return async (dispatch: any) => {
//         try {
//             const res = await axios({
//                 method: "GET",
//                 url: `https://cemsltd.herokuapp.com/api/v1/shop/sales?project=${shopId}&sort=-date&limit=10`
//             });

//             console.log("first id is, ", shopId , " sales data ", res.data.data);
//             dispatch(constructionActions.setSales({sales: res.data.data.data}));
//         } catch(err) {
//             console.log(err);
//         }
//     }
// }

// export const getShopItems = (shopId: string) => {
//     return async (dispatch: any) => {
//         try {
//             const res = await axios({
//                 method: "GET",
//                 url: `https://cemsltd.herokuapp.com/api/v1/items?project=${shopId}`
//             });

//             console.log("data ", res.data.data);
//             // dispatch(constructionActions.setPurchases({shopProject: res.data.data}));
//         } catch(err) {
//             console.log(err);
//         }
//     }
// }

// export const getShopCustomers = (shopId: string) => {
//     return async (dispatch: any) => {
//         try {
//             const res = await axios({
//                 method: "GET",
//                 url: `https://cemsltd.herokuapp.com/api/v1/customers?project=${shopId}`
//             });

//             console.log("data ", res.data.data);
//             // dispatch(constructionActions.setPurchases({shopProject: res.data.data}));
//         } catch(err) {
//             console.log(err);
//         }
//     }
// }

// export const getShopSuppliers = (shopId: string) => {
//     return async (dispatch: any) => {
//         try {
//             const res = await axios({
//                 method: "GET",
//                 url: `https://cemsltd.herokuapp.com/api/v1/suppliers?project=${shopId}`
//             });

//             console.log("data ", res.data.data);
//             // dispatch(constructionActions.setPurchases({shopProject: res.data.data}));
//         } catch(err) {
//             console.log(err);
//         }
//     }
// }

// export const getShopStock = (shopId: string) => {
//     return async (dispatch: any) => {
//         try {
//             const res = await axios({
//                 method: "GET",
//                 url: `https://cemsltd.herokuapp.com/api/v1/stock?project=${shopId}`
//             });

//             console.log("data ", res.data.data);
//             // dispatch(constructionActions.setPurchases({shopProject: res.data.data}));
//         } catch(err) {
//             console.log(err);
//         }
//     }
// }
