import { useDispatch, useSelector } from 'react-redux';
import { useRef, useState, useEffect } from 'react';
import { Button, Typography, Box, CircularProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SearchIcon from '@mui/icons-material/Search';
import InputComponent from '../Inputs/InputComponent';
import SelectComponent from '../Inputs/SelectComponent';
import styles from '../../index.module.scss';
import { getClients } from '../../../store/construction/constructionActions';
import { getProjectCategories } from '../../../store/projects/projectActions';
import { constructionActions } from '../../../store/construction';
import { projectActions } from '../../../store/projects';
import { ChipData, LooseObject } from '../../../interfaces/constants';

export default function Filter(props: any) {
  const [init, setInit] = useState(true);
  const [fetching, setFetching] = useState(false);
  const [saving, setSaving] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const dispatch = useDispatch();
  const [status, setStatus] = useState('');
  const [partner, setPartner] = useState('');
  const nameRef = useRef<HTMLInputElement>(null);
  const amountRef = useRef<HTMLInputElement>(null);
  const dateRef = useRef<HTMLInputElement>(null);
  const locationRef = useRef<HTMLInputElement>(null);
  const categories = useSelector((state: any) => state.project.categories);
  const clients = useSelector((state: any) => state.construction.clients);
  // const nameRef = useRef<HTMLInputElement>(null);

  var statusValues = [
    {
      name: 'Open',
      _id: 'open',
    },
    {
      name: 'Closed',
      _id: 'closed',
    },
  ];

  const handleStatus = (event: any) => {
    setStatus(event.target.value as string);
  };

  const handlePartnerChange = (event: any) => {
    setPartner(event.target.value as string);
  };

  useEffect(() => {
    if (init) {
      console.log('run everytime  ...');
      setFetching(true);
      dispatch(getClients());
      dispatch(getProjectCategories());
    }
    return () => {
      setInit(false);
    };
  }, [dispatch, init]);

  useEffect(() => {
    if (clients.length > 0 && categories.length > 0) {
      console.log('runiiigd everytime  ...');
      setFetching(false);
    }
  }, [dispatch, clients, categories]);

  const filter = () => {
    let chipData = [];
    let key = 0;
    if (partner) {
      chipData.push({ key, filter: partner, id: 'client', label: 'Client', value: 'Sebat' });
      key++;
    }
    if (status) {
      chipData.push({ key, filter: status, id: 'status', label: 'Status' });
      key++;
    }
    if (amountRef.current?.value) {
      chipData.push({ key, filter: amountRef.current?.value, id: 'contractAmount', label: 'Amount' });
      key++;
    }

    if (chipData.length > 0) {
      props.filterHandler(chipData);
      props.setOpen(false);
    } else {
      props.setOpen(false);
    }
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => props.setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={`${styles.formHeader} ${styles.center}`}>
          <Typography className={styles.formHeaderText}>FILTER / ADVANCED SEARCH</Typography>
        </DialogTitle>
        <DialogContent className={styles.formContent}>
          {fetching && (
            <Box className={styles.loadingContainer}>
              <CircularProgress />
            </Box>
          )}
          {!fetching && (
            <Box>
              {clients.length > 0 && (
                <SelectComponent label="Client" value={partner} values={clients} handleChange={handlePartnerChange} />
              )}
              <SelectComponent label="Status" value={status} values={statusValues} handleChange={handleStatus} />
              <InputComponent
                label="Purchase Order Amount"
                reference={amountRef}
                icon={<SearchIcon />}
                placeholder="Filter by PO Amount"
              />
            </Box>
          )}
        </DialogContent>
        <DialogActions className={styles.center}>
          <Box className={styles.filterButtonsContainer}>
            <Button onClick={() => filter()} variant="contained" className={styles.filterButton}>
              Filter
            </Button>
            <Button onClick={() => props.setOpen(false)} variant="contained" className={styles.cancelButton}>
              Clear
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}
