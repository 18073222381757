import * as React from 'react';
import { Box, Button, Container, Grid, Paper, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SearchIcon from '@mui/icons-material/Search';
import InputComponent from '../Inputs/InputComponent';
import styles from '../../index.module.scss';

export default function NewShopPurchase(props: any) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Container classes={{ root: styles.newPurchaseContainer }}>
      <Typography className={styles.testt}>Test</Typography>
      <Dialog classes={{ paper: styles.paper }} open={props.open} onClose={() => props.setOpen(false)}>
        <DialogTitle>
          <Typography>New Purchase</Typography>
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid container item xs={8}>
              <Grid item xs={6}>
                <InputComponent label="Supplier" icon={<SearchIcon />} placeholder="Add Client Name" />
                <InputComponent label="Item" icon={<SearchIcon />} placeholder="Add Client Name" />
                <InputComponent label="Quantity" icon={<SearchIcon />} placeholder="Add Client Name" />
                <InputComponent label="Total Price" icon={<SearchIcon />} placeholder="Add Client Name" />
              </Grid>
              <Grid xs={6}>
                <InputComponent label="Manufacture" icon={<SearchIcon />} placeholder="Enter the Stock/Manufacturer" />
                <InputComponent label="Date" icon={<SearchIcon />} placeholder="Add Client Name" />
                <InputComponent label="Unit Price" icon={<SearchIcon />} placeholder="Add Client Name" />
                <InputComponent label="Total Paid" icon={<SearchIcon />} placeholder="Add Client Name" />
              </Grid>
            </Grid>
            <Grid item xs={4}>
              Facture
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box>
            <Button onClick={handleClose} variant="contained">
              Save
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Container>
  );
}
