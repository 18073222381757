import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material';
import InputComponent from '../Inputs/InputComponent';
import CloseIcon from '@mui/icons-material/Close';
import styles from '../../index.module.scss';
import FormInput from '../Inputs/FormInput';
import FormSelect from '../Inputs/FormSelect';

const GenericForm = (props: any) => {
  return (
    <Dialog classes={{ paper: styles.genericForm }} open={props.open} onClose={() => props.setOpen(false)}>
      <DialogTitle className={styles.genericForm_header}>
        <Box></Box>
        <Typography className={styles.form_title}>{props?.title}</Typography>

        <CloseIcon className={styles.closeButton} onClick={() => props.setOpen(false)} />
      </DialogTitle>

      <DialogContent>
        <Box className={styles.genericForm_column}>
          {props.formElements.map((element: any, index: number) => {
            console.log(element.type, element.type === 'input');
            if (element.type === 'input') {
              return (
                <FormInput
                  key={index}
                  label={element.label}
                  reference={element.reference}
                  placeholder={element.placeholder}
                />
              );
            } else if (element.type === 'select') {
              return (
                <FormSelect
                  key={index}
                  label={element.label}
                  value={element?.value}
                  values={element?.values}
                  setValue={element?.setValue}
                  id={element?.id}
                  name={element?.name}
                />
              );
            }
          })}
        </Box>
        {/* <Grid container>
          <Grid item xs={6}></Grid>
          <Grid xs={6}>
            <Box className={styles.genericForm_column}></Box>
          </Grid>
        </Grid> */}
      </DialogContent>
      <DialogActions>
        <Box className={styles.center}>
          <Button className={styles.confirmButton} onClick={() => props.onSubmitted()} variant="contained">
            Save
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default GenericForm;
