import { List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { Link } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import styles from './index.module.scss';
import { darkBlue, textColor } from '../../assets/colors/colors';
import store from 'store';
import { useSelector, useDispatch } from 'react-redux';
import { authActions } from '../../store/auth';

const HomeNavMenu = (props: any) => {
  const dispatch = useDispatch();
  const { select } = props;

  const NavItems = [
    { label: 'Dashboard', icon: <DashboardIcon style={{ color: 'currentColor' }} />, path: '/' },
    { label: 'Shops', icon: <LocalGroceryStoreIcon style={{ color: 'currentColor' }} />, path: '/shop' },
    { label: 'Account', icon: <PersonIcon style={{ color: 'currentColor' }} />, path: '/home/account' },
    { label: 'Users', icon: <RecentActorsIcon style={{ color: 'currentColor' }} />, path: '/home/users' },
    { label: 'Expenses', icon: <MoneyOffIcon style={{ color: 'currentColor' }} />, path: '/home/expenses' },
    { label: 'Clients', icon: <PeopleAltIcon style={{ color: 'currentColor' }} />, path: '/home/clients' },
    { label: 'Logout', icon: <ExitToAppIcon style={{ color: 'currentColor' }} />, path: '/login' },
  ];
  return (
    <List>
      {NavItems.map((nav, index) => (
        <Link
          key={index}
          to={nav.path}
          className={styles.link}
          onClick={() => {
            nav.label === 'Logout' && store.remove('x-auth-token');
            nav.label === 'Logout' && store.remove('user');
            nav.label === 'Logout' && dispatch(authActions.logout());
          }}
        >
          <ListItem selected={index === select} classes={{ root: styles.root, selected: styles.listItemSelected }}>
            <ListItemIcon style={{ color: index === select ? darkBlue : textColor }}>{nav.icon}</ListItemIcon>
            <ListItemText>{nav.label}</ListItemText>
          </ListItem>
        </Link>
      ))}
    </List>
  );
};

export default HomeNavMenu;
