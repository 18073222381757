import { useState } from 'react';
import ConstructionLayout from '../../components/layout/Construction';
import { useSelector } from 'react-redux';
import TopActions from '../../components/layout/TopActions';
import ExpensesTable from '../../components/Construction/Project/ExpensesTable';
import NewExpenseForm from '../../components/UI/Forms/newExpense';
import { Typography } from '@mui/material';
import styles from './index.module.scss';
import Banner from '../../components/Construction/Banner';

const ConstructionExpenses = () => {
  const [newExpense, setNewExpense] = useState(false);
  const project = useSelector((state: any) => state.construction.selectedProject);

  return (
    <ConstructionLayout select={1}>
      <Typography className={styles.navTitle}>{`${project.name} • Expenses`}</Typography>
      {/* <ConstructionBanner /> */}
      {newExpense && <NewExpenseForm open={newExpense} project={project.id} setOpen={setNewExpense} />}
      <TopActions setNew={setNewExpense} entity="Expense" />
      <Banner id={project.id} />
      <ExpensesTable id={project.id} />
    </ConstructionLayout>
  );
};

export default ConstructionExpenses;
