export interface shopItemColumn {
  id: 'creationDate' | 'name' | 'manufacturer' | 'buyingPrice' | 'sellingPrice' | 'action';
  label: String;
  minWidth?: number;
  align?: 'center';
}

export const shopItemColumns: shopItemColumn[] = [
  { id: 'creationDate', label: 'Creation Date', align: 'center' },
  { id: 'name', label: 'Item Name', align: 'center' },
  { id: 'manufacturer', label: 'Manufacturer', align: 'center' },
  { id: 'buyingPrice', label: 'Buying Price', align: 'center' },
  { id: 'sellingPrice', label: 'Selling Price', align: 'center' },
  { id: 'action', label: 'Action', align: 'center' },
];

export interface shopItemData {
  id: string;
  creationDate: string;
  name: string;
  manufacturer: string;
  buyingPrice: string;
  sellingPrice: string;
  action: any;
}
