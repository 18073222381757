import React, { useState } from 'react';
import { Paper, Typography, Grid, Pagination } from '@mui/material';
import Divider from '@mui/material/Divider';
import styles from '../index.module.scss';

const LabelComp = (props: any) => (
  <Typography variant="body2" color="text.secondary" style={{ padding: '2px', color: `${props?.color}` }}>
    <span style={{ fontWeight: '600', color: 'black' }}>{props?.title}: </span> {props?.dataText}
  </Typography>
);

const nn = [1, 2, 3, 4, 5, 6];
const StockInCard = () => {
  const [tabIndex, setTabIndex] = useState(1);
  return (
    <>
      {nn.map(
        (n: any, index: any) =>
          index >= tabIndex * 5 - 5 &&
          index < tabIndex * 5 && (
            <>
              <Paper elevation={1} className={styles.stockInCard}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <LabelComp title="Date" dataText="10/08/2022" color="blue" />
                    <LabelComp title="Quantity" dataText={n} />
                    <LabelComp title="Unity/Price" dataText={1000} />
                    <LabelComp title="Total" dataText={12000} />
                  </Grid>
                  <Grid item xs={6}>
                    <LabelComp title="Done By" dataText={'Iyamuremye'} />
                    <LabelComp title="Suplier" dataText={1000} />
                    <LabelComp title="Payment" dataText={'Not Paid'} color="red" />
                  </Grid>
                </Grid>
                {/* <Divider /> */}
              </Paper>
            </>
          )
      )}

      <Pagination
        count={Math.ceil(nn.length / 5)}
        showFirstButton
        showLastButton
        onChange={(event: React.ChangeEvent<unknown>, value: number) => {
          setTabIndex(value);
        }}
      />
    </>
  );
};

export default StockInCard;
