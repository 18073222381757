import ConstructionLayout from "../../components/layout/Construction";

const ConstructionSettings = () => {
    return (
        <ConstructionLayout select={6}>
            <h4>Construction Settings</h4>
        </ConstructionLayout>
    );
}

export default ConstructionSettings;