import axios from 'axios';
import { shopActions } from '.';
import { SERVER_URL } from '../../utils/constants';
import { projectActions } from '../projects';

export const getShopProjects = (query: string) => {
  return async (dispatch: any) => {
    try {
      const res = await axios({
        method: 'GET',
        url: `${SERVER_URL}/api/v1/project/shopprojects${query}`,
      });

      const shops = res.data.data.data;
      dispatch(shopActions.setProjects(shops));
      dispatch(projectActions.setIsFetching(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getShopProject = (projectId: string) => {
  return async (dispatch: any) => {
    try {
      const res = await axios({
        method: 'GET',
        url: `${SERVER_URL}/api/v1/project/shopprojects?project=${projectId}`,
      });

      const shop = res.data.data.data[0];
      console.log('shop iss s s s s ', projectId, shop);
      dispatch(shopActions.setSelectedShop({ shopProject: shop }));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getShopSales = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      const res = await axios({
        method: 'GET',
        url: `${SERVER_URL}/api/v1/shop/sales?${query}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch(shopActions.setSales(res.data.data.data));
      dispatch(projectActions.setCount({ count: res.data.count }));
      dispatch(projectActions.setIsFetching(false));
      dispatch(projectActions.setUpdateNeeded(false));
    } catch (err: any) {
      console.log(err.response.data.message);
    }
  };
};

export const searchShopSales = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      const res = await axios({
        method: 'GET',
        url: `${SERVER_URL}/api/v1/shop/sales/search_item${query}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(shopActions.setSales(res.data.data.data));
      dispatch(projectActions.setCount({ count: res.data.count }));
      dispatch(projectActions.setIsFetching(false));
      dispatch(projectActions.setUpdateNeeded(false));
    } catch (err: any) {
      console.log(err.response);
    }
  };
};

export const getShopCustomers = (shopId: string) => {
  return async (dispatch: any) => {
    try {
      const res = await axios({
        method: 'GET',
        url: `${SERVER_URL}/api/v1/shop/customers?project=${shopId}`,
      });

      console.log('data ', res.data.data);
    } catch (err) {
      console.log(err);
    }
  };
};

export const getShopManufacturers = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      const res = await axios({
        method: 'GET',
        url: `${SERVER_URL}/api/v1/shop/manufacturers?${query}`,
      });

      console.log('dataaaaaaaaaaaaaaaaaaaaaaa ', res.data.data.data);
      dispatch(shopActions.setManufacturers(res.data.data.data));
    } catch (err) {
      console.log(err);
    }
  };
};

export const getShopSuppliers = (shopId: string) => {
  return async (dispatch: any) => {
    try {
      const res = await axios({
        method: 'GET',
        url: `${SERVER_URL}/api/v1/shop/suppliers?project=${shopId}`,
      });

      console.log('data ', res.data.data);
    } catch (err) {
      console.log(err);
    }
  };
};

export const getShopStock = (shopId: string) => {
  return async (dispatch: any) => {
    try {
      const res = await axios({
        method: 'GET',
        url: `${SERVER_URL}/api/v1/shop/stock?project=${shopId}`,
      });

      console.log('data ', res.data.data);
    } catch (err) {
      console.log(err);
    }
  };
};
