import axios from 'axios';
import { SERVER_URL } from '../../utils/constants';

export const ConstructionService = {
  closeProject: async (id: string) => {
    try {
      const res = await axios({
        method: 'PATCH',
        url: `${SERVER_URL}/api/v1/project/constructionprojects/${id}`,
        data: {
          status: 'closed',
        },
      });
      console.log(res.data.data.data);
    } catch (err: any) {
      console.log(err.response.data.message);
    }
  },
};

export const addClientService = async (data: any) => {
  try {
    await axios({
      method: 'POST',
      url: `${SERVER_URL}/api/v1/construction/clients`,
      data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const addStock = async (data: any) => {
  try {
    await axios({
      method: 'POST',
      url: `${SERVER_URL}/api/v1/construction/projectstock`,
      data,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getStocksByProjectId = async (id: any) => {
  try {
    return await axios({
      method: 'GET',
      url: `${SERVER_URL}/api/v1/construction/projectstock/project/${id}`,
    });
  } catch (err) {
    console.log(err);
  }
};

export const getItemsByStockId = async (id: any) => {
  try {
    return await axios({
      method: 'GET',
      url: `${SERVER_URL}/api/v1/construction/stockitems/stock/${id}`,
    });
  } catch (err) {
    console.log(err);
  }
};
