import axios from 'axios';
import { SERVER_URL } from '../../utils/constants';

export const loginService = async (data: any) => {
  try {
    const res = await axios({
      method: 'POST',
      url: `${SERVER_URL}/api/v1/project/users/login`,
      data,
    });
    return res;
  } catch (err: any) {
    console.log('LLLLL:::', err.response);

    return err?.response;
  }
};
