import React from 'react';

import { Box, Grid, Typography } from '@mui/material';
import styles from '../../pages/Home/index.module.scss';

const MetricsCard = (props: any) => {
  return (
    <Box className={styles.metricsCardContainer}>
      <Grid
        style={{ backgroundColor: props.background, border: `2px solid ${props.border}` }}
        className={styles.metricsCard}
        container
        spacing={1}
      >
        <Grid className={styles.metricsCardFlex} item xs={9}>
          <Typography className={styles.metricsCardTitle}>{props.title}</Typography>
          <Typography classes={{ root: styles.metric }}>{props.metric}</Typography>
        </Grid>
        <Grid className={styles.metricsCardFlex} item xs={3}>
          {props.avatar}
        </Grid>
      </Grid>
    </Box>
  );
};

export default MetricsCard;
