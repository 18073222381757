import { List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { Link } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import DescriptionIcon from '@mui/icons-material/Description';
import HomeIcon from '@mui/icons-material/Home';
import styles from './index.module.scss';
import { darkBlue, textColor } from '../../assets/colors/colors';
import { useSelector, useDispatch } from 'react-redux';

const ConstructionNavMenu = (props: any) => {
  const { select } = props;
  const projectState = useSelector((state: any) => state.construction?.selectedProject);

  // { label: 'Payments', icon: <DashboardIcon style={{ color: 'currentColor' }} />, path: '/construction/payments' },
  // {
  //   label: 'Project Settings',
  //   icon: <DashboardIcon style={{ color: 'currentColor' }} />,
  //   path: '/construction/settings',
  // },
  // { label: 'Dashboard', icon: <DashboardIcon style={{ color: 'currentColor' }} />, path: '/construction' },
  const NavItems = [
    { label: 'HOME', icon: <HomeIcon />, path: '/' },
    { label: 'Expenses', icon: <MoneyOffIcon style={{ color: 'currentColor' }} />, path: '/construction/expenses' },
    {
      label: 'Stock',
      icon: <DashboardIcon style={{ color: 'currentColor' }} />,
      path: `/construction/stock/${projectState?.id}`,
    },
    { label: 'PayRoll', icon: <DashboardIcon style={{ color: 'currentColor' }} />, path: '/construction/payroll' },
    {
      label: 'Documents',
      icon: <DescriptionIcon style={{ color: 'currentColor' }} />,
      path: '/construction/documents',
    },
  ];
  return (
    <List>
      {NavItems.map((nav, index) => (
        <Link key={index} to={nav.path} className={styles.link}>
          <ListItem selected={index === select} classes={{ root: styles.root, selected: styles.listItemSelected }}>
            <ListItemIcon style={{ color: index === select ? darkBlue : textColor }}>{nav.icon}</ListItemIcon>
            <ListItemText>{nav.label}</ListItemText>
          </ListItem>
        </Link>
      ))}
    </List>
  );
};

export default ConstructionNavMenu;
