import { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ShopLayout from '../../../components/layout/Shop';
import PurchasesTable from './purchasesTable';
import styles from '../index.module.scss';
import NewShopPurchase from '../../../components/UI/Forms/NewPurchase';
import { searchShopPurchases } from '../../../store/shop/shopActions/Purchases';

const Purchases = () => {
  const shopState = useSelector((state: any) => state.shop);

  return (
    <ShopLayout select={2}>
      <Typography className={styles.navTitle}>{`${shopState?.selectedShop?.project?.name} • PURCHASES`}</Typography>
      <PurchasesTable />
    </ShopLayout>
  );
};

export default Purchases;
