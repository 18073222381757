import { Box, CircularProgress } from '@mui/material';
import styles from '../../../pages/Home/index.module.scss';

const LoadingContainer = () => {
  return (
    <Box className={styles.loadingContainer}>
      <CircularProgress />
    </Box>
  );
};

export default LoadingContainer;
