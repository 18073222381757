import React, { useEffect, useState } from 'react';
import { Box, Typography, TextField, Alert, AlertTitle, Collapse, Card, CardContent } from '@mui/material';
import styles from './index.module.scss';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import logo from '../../assets/images/logo.png';
import { useNavigate } from 'react-router-dom';
import LoadingButton from '@mui/lab/LoadingButton';

import { useSelector, useDispatch } from 'react-redux';
import { loginAction } from '../../store/auth/authActions';

const Login = () => {
  const dispatch = useDispatch();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const { status, isFetching } = useSelector((state: any) => state?.auth);

  const handleLogin = () => {
    console.log('>>>');
    loginAction({ username, password })(dispatch);
  };
  useEffect(() => {
    if (status === 200) {
      navigate('/');
    } else if (status === 401) {
      setOpen(true);
      setTimeout(()=>{setOpen(false)},4000);
    }
  }, [status,isFetching]);
  return (
    <>
      <Box className={styles.loginContainer}>
      <Collapse in={open}>
        <Alert severity="error"
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setOpen(false);
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
          sx={{ mb: 2 }}
        >
         Wrong credentials! Please use correct email and password.
        </Alert>
      </Collapse>
        <Box className={styles.loginContainerTwo}>
          <Card className={styles.loginBox}>
            <CardContent className={styles.loginBoxContent}>
              <img src={logo} alt="Logo" width="25%" />
              <Typography className={`${styles.centerText} ${styles.loginText}`}>LOGIN</Typography>
              <TextField
                required
                id="outlined-required"
                label="Username"
                type="email"
                variant="outlined"
                className={styles.inputFields}
                onChange={(e) => setUsername(e.target.value)}
              />
              <TextField
                id="outlined-password-input"
                label="Password"
                type="password"
                variant="outlined"
                className={styles.inputFields}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Box className={styles.spaceBetween}>
                <Typography>Remember Me</Typography>
                <Typography>Fogort Password?</Typography>
              </Box>
              <LoadingButton
                variant="contained"
                className={styles.loginButton}
                loading={isFetching}
                size="large"
                onClick={() => {
                  // console.log('meee');
                  handleLogin();
                }}
              >
                LOGIN
              </LoadingButton>
              {/* <Typography>Don't have account? Click to Sign Up</Typography> */}
            </CardContent>
          </Card>
        </Box>
      </Box>
    </>
  );
};

export default Login;
