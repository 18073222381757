import React, { useEffect } from 'react';
import { Avatar, Box } from '@mui/material';
import styles from '../../pages/Home/index.module.scss';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import MetricsCard from './MetricsCard';
import { deleteRed, primaryGreen, primaryPurple, primaryRed } from '../../assets/colors/colors';
import { getProject } from '../../store/construction/constructionActions';
import { useDispatch, useSelector } from 'react-redux';
import { numberWithCommas } from '../../utils/functions';

const Banner = (props: any) => {
  const dispatch = useDispatch();
  const constructionState = useSelector((state: any) => state.construction);

  useEffect(() => {
    dispatch(getProject(props.id));
  }, [dispatch, props.id]);

  return (
    <Box className={styles.banner}>
      {constructionState?.project?.contractAmount && (
        <>
          <MetricsCard
            title="PO AMOUNT, VAT Exclusive"
            metric={`${numberWithCommas(parseInt((constructionState?.project?.contractAmount / 1.18).toFixed(0)))} Rwf`}
            background="#2c358d05"
            border="#2c358d14"
            avatar={
              <Avatar style={{ height: '3rem', width: '3rem', backgroundColor: primaryPurple }}>
                <AttachMoneyIcon />
              </Avatar>
            }
          />
          <MetricsCard
            title="TOTAL Expenses"
            metric={`${numberWithCommas(constructionState?.project?.totalExpenses)} Rwf`}
            background="#ed5e6805"
            border="#ed5e6814"
            avatar={
              <Avatar style={{ height: '3rem', width: '3rem', backgroundColor: deleteRed }}>
                <ArrowForwardIcon />
              </Avatar>
            }
          />
          <MetricsCard
            title="BALANCE"
            metric={`${numberWithCommas(
              parseInt(
                (constructionState?.project?.contractAmount / 1.18 - constructionState?.project?.totalExpenses).toFixed(
                  0
                )
              )
            )} Rwf`}
            background="#00a44b05"
            border="#00a44b14"
            avatar={
              <Avatar style={{ height: '3rem', width: '3rem', backgroundColor: primaryGreen }}>
                <AccountBalanceWalletIcon />
              </Avatar>
            }
          />
          <MetricsCard
            title="PO AMOUNT, VAT Inclusive"
            metric={`${numberWithCommas(parseInt((constructionState?.project?.contractAmount).toFixed(0)))} Rwf`}
            background="#2c358d05"
            border="#2c358d14"
            avatar={
              <Avatar style={{ height: '3rem', width: '3rem', backgroundColor: primaryPurple }}>
                <AttachMoneyIcon />
              </Avatar>
            }
          />
        </>
      )}
    </Box>
  );
};

export default Banner;
