import axios from 'axios';
import { shopActions } from '..';
import { SERVER_URL } from '../../../utils/constants';
import { projectActions } from '../../projects';

export const getShopPurchases = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      const res = await axios({
        method: 'GET',
        url: `${SERVER_URL}/api/v1/shop/purchases?${query}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch(shopActions.setPurchases(res.data.data.data));
      dispatch(projectActions.setCount({ count: res.data.count }));
      dispatch(projectActions.setIsFetching(false));
      dispatch(projectActions.setUpdateNeeded(false));
    } catch (err: any) {
      console.log(err.response);
    }
  };
};

export const searchShopPurchases = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      const items = await axios({
        method: 'GET',
        url: `${SERVER_URL}/api/v1/shop/items${query}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      let purchases: any[] = [];

      for (let item of items.data.data.data) {
        const res = await axios({
          method: 'GET',
          url: `${SERVER_URL}/api/v1/shop/purchases?item=${item._id}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        purchases = [...purchases, ...res.data.data.data];
      }

      dispatch(shopActions.setPurchases(purchases));
      dispatch(projectActions.setIsFetching(false));
      dispatch(projectActions.setUpdateNeeded(false));
    } catch (err: any) {
      console.log(err.response);
    }
  };
};
