import { useState, useEffect } from 'react';
import {
  Box,
  TableContainer,
  Table,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  CircularProgress,
  Button,
} from '@mui/material';
import styles from './../index.module.scss';
import { getDocuments } from '../../../store/construction/constructionActions';
import { useDispatch, useSelector } from 'react-redux';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import NewDocument from '../../../components/UI/Forms/NewDocument';

const PurchaseOrders = () => {
  const [newPurchaseOrder, setNewPurchaseOrder] = useState(false);
  // const project = useSelector((state: any) => state.construction.selectedProject);
  const constructionState = useSelector((state: any) => state.construction);
  const projectState = useSelector((state: any) => state.project);
  const dispatch = useDispatch();

  console.log('docs buillssst', projectState?.updateNeeded);

  useEffect(() => {
    dispatch(getDocuments(constructionState?.selectedProject.id));
  }, [dispatch, constructionState?.selectedProject.id]);

  useEffect(() => {
    if (projectState.updateNeeded) dispatch(getDocuments(constructionState?.selectedProject.id));
  }, [projectState.updateNeeded, dispatch, constructionState?.selectedProject.id]);

  return (
    <>
      {!projectState.isFetching && (
        <>
          <Box className={styles.aRight}>
            <Button variant="contained" onClick={() => setNewPurchaseOrder(true)}>
              Upload Document
            </Button>
          </Box>
          <TableContainer component={Paper}>
            <Table
              className={styles.table}
              classes={{ root: styles.customTable }}
              size="small"
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align="center"></TableCell>
                  <TableCell align="center">DOCUMENT NAME</TableCell>
                  <TableCell align="center">DOCUMENT Type</TableCell>
                  <TableCell align="center">File Type</TableCell>
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {constructionState?.documents.map((row: any, index: number) => (
                  <TableRow key={row.date + index}>
                    {/* <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell> */}
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{row?.name}</TableCell>
                    <TableCell align="center">{row?.type}</TableCell>
                    <TableCell align="center">pdf</TableCell>
                    <TableCell align="center">
                      <Box className={styles.poActions}>
                        <a className={styles.link} href={row.url} target="_blank" rel="noopener noreferrer">
                          <VisibilityIcon style={{ marginRight: '10px' }} />
                        </a>
                        <a className={styles.link} href={row.url} target="_blank" download>
                          <CloudDownloadIcon />
                        </a>
                        <DeleteIcon />
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
      {projectState.isFetching && (
        <Box className={styles.loadingContainer}>
          <CircularProgress />
        </Box>
      )}
      {newPurchaseOrder && <NewDocument open={newPurchaseOrder} setOpen={setNewPurchaseOrder} />}
    </>
  );
};

export default PurchaseOrders;
