// import { useState, useEffect } from 'react';
// import { Typography, Button, Box, Divider, Card, CardMedia, CardContent, ImageList, ImageListItem } from '@mui/material';
// import ConstructionLayout from '../../../components/layout/Construction';
// import NewPurchaseOrder from '../../../components/UI/Forms/NewPurchaseOrder';
// import FolderIcon from '@mui/icons-material/Folder';
// import { primaryPurple } from '../../../assets/colors/colors';
// import styles from './../index.module.scss';
// import { getDocuments } from '../../../store/construction/constructionActions';
// import { useDispatch, useSelector } from 'react-redux';
// import pdf from '../../assets/icons/pdf.svg';

// const ConstructionImages = () => {
//   const [newPurchaseOrder, setNewPurchaseOrder] = useState(false);
//   // const project = useSelector((state: any) => state.construction.selectedProject);
//   const projectState = useSelector((state: any) => state.construction);
//   const fetching = useSelector((state: any) => state.project.isFetching);
//   const dispatch = useDispatch();
//   const [uploadImageDialogOpen, setUploadImageDialogOpen] = useState(false);

//   console.log('docs buillssst', projectState?.purchaseOrders);

//   useEffect(() => {
//     dispatch(getDocuments(projectState?.selectedProject.id));
//   }, [dispatch, projectState?.selectedProject.id]);
//   return (
//     <>
//     <Typography>Gallery</Typography>
//         <Box className={styles.galleryContainer}>
//           <ImageList rowHeight={180} className={styles.imageList} cols={4}>
//             {serviceState?.service?.images?.map((item) => (
//               <ImageListItem key={item.cloudinary_id} cols={item.cols || 1}>
//                 <img src={item.url} alt={item.cloudinary_id} />
//               </ImageListItem>
//             ))}
//           </ImageList>
//         </Box>
//         <Box className={styles.center}>
//           <Button
//             className={styles.addImageButton}
//             variant="contained"
//             onClick={handleClickOpen}
//             startIcon={<AddPhotoAlternateIcon />}
//           >
//             Add Image
//           </Button>
//         </Box>
//         {uploadImageDialogOpen && (
//           <UploadImageDialog
//             open={uploadImageDialogOpen}
//             handleClickOpen={handleClickOpen}
//             handleClose={handleClose}
//             service={service}
//           />
//         )}
//     </>
//   );
// };

// export default ConstructionImages;
import { useState, useEffect } from 'react';
import {
  Typography,
  Button,
  Box,
  Divider,
  Card,
  CardMedia,
  CardContent,
  ImageList,
  ImageListItem,
} from '@mui/material';
import ConstructionLayout from '../../../components/layout/Construction';
import styles from '../index.module.scss';
import { getDocuments, getImages } from '../../../store/construction/constructionActions';
import { useDispatch, useSelector } from 'react-redux';
import NewImage from '../../../components/UI/Forms/NewImage';

const ConstructionImages = () => {
  const [newPurchaseOrder, setNewPurchaseOrder] = useState(false);
  const [newImage, setNewImage] = useState(false);
  const constructionState = useSelector((state: any) => state.construction);
  const projectState = useSelector((state: any) => state.project);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getImages(constructionState?.selectedProject.id));
  }, [dispatch, constructionState?.selectedProject.id]);

  useEffect(() => {
    if (projectState.updateNeeded) dispatch(getImages(constructionState?.selectedProject.id));
  }, [projectState.updateNeeded, dispatch, constructionState?.selectedProject.id]);

  return (
    <>
      <Box className={styles.aRight}>
        <Button variant="contained" onClick={() => setNewImage(true)}>
          Upload Image
        </Button>
      </Box>
      <Box className={styles.galleryContainer}>
        <ImageList rowHeight={180} className={styles.imageList} cols={4}>
          {constructionState?.images?.map((image: any) => (
            <ImageListItem key={image.cloudinary_id} cols={1}>
              <img src={image.url} alt={image.cloudinary_id} />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {newImage && <NewImage open={newImage} setOpen={setNewImage} />}
    </>
  );
};

export default ConstructionImages;
