import React from 'react';
import { Paper, Typography, Grid } from '@mui/material';
import Divider from '@mui/material/Divider';
import styles from '../index.module.scss';

const LabelComp = (props: any) => (
  <Typography variant="body2" color="text.secondary" style={{ padding: '2px' }}>
    <span style={{ fontWeight: '600' }}>{props?.title}: </span> {props?.dataText}
  </Typography>
);

const nn = [1, 2, 3, 4, 5, 6];
const StockOutCard = () => {
  return (
    <>
      {nn.map((n: any) => (
        <>
          <Paper elevation={1} className={styles.stockInCard} >
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <LabelComp title="Date" dataText="10/08/2022" />
                <LabelComp title="Quantity" dataText={12} />
              </Grid>
              <Grid item xs={7}>
                <LabelComp title="Done By" dataText={'Iyamuremye'} />
                <LabelComp title="Designeted By" dataText={"Eric"} />
                <LabelComp title="Approved By" dataText={"Nshimiye"} />
              </Grid>
            </Grid>
            <Divider />
          </Paper>
        </>
      ))}
    </>
  );
};

export default StockOutCard;
