export interface shopSaleColumn {
  id:
    | 'date'
    | 'customer'
    | 'item'
    | 'manufacturer'
    | 'quantity'
    | 'buyingPrice'
    | 'sellingPrice'
    | 'totalPrice'
    | 'totalPaid'
    | 'profit'
    | 'action';
  label: String;
  minWidth?: number;
  align?: 'center';
}

export const shopSaleColumns: shopSaleColumn[] = [
  { id: 'date', label: 'Date', align: 'center' },
  { id: 'customer', label: 'Customer', align: 'center' },
  { id: 'item', label: 'Item Name', align: 'center' },
  { id: 'manufacturer', label: 'Manufacturer', align: 'center' },
  { id: 'quantity', label: 'Quantity', align: 'center' },
  { id: 'buyingPrice', label: 'Buying Price', align: 'center' },
  { id: 'sellingPrice', label: 'Selling Price', align: 'center' },
  { id: 'totalPrice', label: 'Total Price', align: 'center' },
  { id: 'totalPaid', label: 'Total Paid', align: 'center' },
  { id: 'profit', label: 'Profit', align: 'center' },
  { id: 'action', label: 'Action', align: 'center' },
];

export interface shopSaleData {
  id: string;
  customer: string;
  date: string;
  item: string;
  manufacturer: string;
  quantity: string;
  buyingPrice: string;
  sellingPrice: string;
  totalPrice: string;
  totalPaid: string;
  profit: string;
  action: any;
}

//Date	Customer	Item	Quantity	 buyingPrice 	 totalCost 	 SellingPrice 	 totalPrice 	 totalPaid 	 Profit
export interface saleExportData {
  date: string;
  customer: string;
  item: string;
  quantity: number;
  buyingPrice: number;
  totalCost: number;
  sellingPrice: number;
  totalPrice: number;
  totalPaid: number;
  profit: number;
}
