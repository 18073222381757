import React, { useState } from 'react';
import { Typography, Box, Menu, MenuItem, CircularProgress, Avatar } from '@mui/material';
import TableComponent from '../../../components/UI/others/TableComponent';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ConfirmModal from '../../../components/UI/others/ConfirmModal';
import { projectColumns, projectData } from '../../../interfaces/projects';
import { deleteRed, primaryGreen } from '../../../assets/colors/colors';
import { useSelector, useDispatch } from 'react-redux';
import { ConstructionService } from '../../../store/construction/constructionService';
import { projectActions } from '../../../store/projects';
import styles from '../index.module.scss';
import { constructionActions } from '../../../store/construction';
import { useNavigate } from 'react-router-dom';
import { numberWithCommas } from '../../../utils/functions';
import ProjectView from './ProjectView';

const DashboardTable = () => {
  const [projectName, setProjectName] = useState('');
  const [projectId, setProjectId] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [projectViewOpen, setProjectViewOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(25);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const constructionState = useSelector((state: any) => state.construction);
  const projectsState = useSelector((state: any) => state.project);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const closeProjectHandler = async () => {
    await ConstructionService.closeProject(projectId);
    setShowModal(false);
    dispatch(projectActions.setUpdateNeeded(true));
  };

  const handleCloseAction = () => {
    handleClose();
    setShowModal(true);
  };

  const handleEditAction = () => {
    handleClose();
    setProjectViewOpen(true);
  };

  const handleDeleteAction = () => {
    setAnchorEl(null);
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const rowClickedHandler = (id: string, projectId: string, project: any) => {
    delete project.action;
    delete project.status;
    dispatch(constructionActions.setSelectedProject(project));
    navigate(`construction/expenses`);
  };

  const createData = (
    id: string,
    projectId: string,
    name: string,
    client: string,
    startDate: string,
    po: number,
    status: string,
    slug: string
  ): projectData => {
    return {
      id,
      projectId,
      name,
      client,
      startDate,
      po: numberWithCommas(po),
      status: (
        <>
          <Avatar
            style={{
              height: 10,
              width: 10,
              display: 'inline-block',
              marginRight: '5px',
              backgroundColor: status === 'closed' ? deleteRed : primaryGreen,
            }}
          >
            {' '}
          </Avatar>
          {status.charAt(0).toUpperCase() + status.slice(1)}
        </>
      ),
      slug,
      action: (
        <button
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setProjectName(name);
            setProjectId(id);
            handleClick(event);
          }}
          className={styles.actionsIcon}
        >
          •••
        </button>
      ),
    };
  };

  const rows = constructionState?.projects?.map((project: any) =>
    createData(
      project._id,
      project.project._id,
      project.project?.name,
      project.client?.name,
      project.startDate.substring(0, 10),
      project?.contractAmount,
      project.status,
      project.project?.slug
    )
  );

  return (
    <>
      {!projectsState.isFetching && (
        <>
          {rows?.length > 0 && (
            <TableComponent
              rows={rows}
              columns={projectColumns}
              rowClickedHandler={rowClickedHandler}
              count={count}
              page={page}
              rowsPerPage={rowsPerPage}
              handlePageChange={handlePageChange}
              handleRowsPerPageChange={handleRowsPerPageChange}
            />
          )}
          {open && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={handleEditAction}>
                <AutorenewIcon />
                <Typography>Edit</Typography>
              </MenuItem>
              <MenuItem onClick={handleCloseAction}>
                <CloseIcon />
                <Typography>Close</Typography>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <DeleteIcon />
                <Typography>Delete</Typography>
              </MenuItem>
            </Menu>
          )}
          {showModal && (
            <ConfirmModal
              open={showModal}
              onCancel={() => setShowModal(false)}
              setOpen={setShowModal}
              onConfirm={closeProjectHandler}
              content={`Are you sure you want to close ${projectName} project`}
            />
          )}
          {projectViewOpen && <ProjectView open={projectViewOpen} setOpen={setProjectViewOpen} />}
        </>
      )}
      {projectsState.isFetching && (
        <Box className={styles.loadingContainer}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default DashboardTable;
