import { createSlice } from '@reduxjs/toolkit';

const constructionProjectSlice = createSlice({
  name: 'constructionProject',
  initialState: {
    projects: [],
    projectId: '',
    project: {},
    selectedProject: {},
    added: false,
    fetching: false,
    expenses: [],
    payments: [],
    stock: [],
    stockItems: [],
    filter: '',
    manPower: [],
    documents: [],
    images: [],
    settings: [],
    clients: [],
  },
  reducers: {
    setFetching(state, action) {
      state.fetching = action.payload;
    },
    setAdded(state, action) {
      state.added = action.payload;
    },
    setFilter(state, action) {
      state.filter = action.payload;
    },
    setProjectId(state, action) {
      state.projectId = action.payload;
    },
    setProjects(state, action) {
      state.projects = action.payload.projects;
    },
    setSelectedProject(state, action) {
      state.selectedProject = action.payload;
    },
    setProject(state, action) {
      state.project = action.payload;
    },
    setExpenses(state, action) {
      state.expenses = action.payload.expenses;
    },
    setPurchaseOrders(state, action) {
      state.documents = action.payload;
    },
    setImages(state, action) {
      state.images = action.payload;
    },
    setPayments(state, action) {
      // console.log("yes setting saless s s s ");
      state.payments = action.payload.payments;
    },
    setStock(state, action) {
      state.stock = action.payload;
    },
    setStockItems(state, action) {
      state.stockItems = action.payload;
    },
    setPartners(state, action) {
      state.clients = action.payload.clients;
    },
  },
});

export const constructionActions = constructionProjectSlice.actions;

export default constructionProjectSlice.reducer;
