import { Select, MenuItem, Box, Typography } from '@mui/material';
import styles from '../../../pages/Home/index.module.scss';

export const onSelectChanged = (event: any, onChange: Function) => {
  onChange(event.target.value as string);
};

const SelectComponent = (props: any) => {
  return (
    <Box className={styles.inputComponent}>
      <Typography className={styles.label}>{props.label}</Typography>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        className={`${styles.input} ${styles.inputSelect}`}
        value={props.value}
        onChange={(event: any) => onSelectChanged(event, props.setValue)}
      >
        {props?.values?.map((option: any) => (
          <MenuItem key={option?._id} value={option?._id}>
            {option[props.name]}
          </MenuItem>
        ))}
        {/* <MenuItem value="62bb0ce366b7b33cc1fa9146">Construction</MenuItem>
                <MenuItem value="62bb0ce466b7b33cc1fa9147">Shop</MenuItem> */}
      </Select>
    </Box>
  );
};

export default SelectComponent;
