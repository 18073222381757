import React from 'react';
import { useRef, useState, useEffect } from 'react';
import { Button, Typography, Box, CircularProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SearchIcon from '@mui/icons-material/Search';
import InputComponent from '../Inputs/InputComponent';
import DatePickerComponent from '../Inputs/datePicker';
import styles from '../../index.module.scss';
import { createExpense, updateExpense } from '../../../store/construction/constructionActions';
import { useDispatch } from 'react-redux';
import { projectActions } from '../../../store/projects';

export default function NewExpenseForm(props: any) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const dateRef = useRef<HTMLInputElement>(null);
  const descriptionRef = useRef<HTMLInputElement>(null);
  const amountRef = useRef<HTMLInputElement>(null);

  const submit = async () => {
    var data = {
      description: descriptionRef.current?.value,
      amount: amountRef.current?.value,
      date: dateRef.current?.value,
      project: props.project,
    };
    setLoading(true);
    if (props.update) {
      await updateExpense(props.expense?.id, data);
    } else {
      await createExpense(data);
    }
    setLoading(false);
    props.setOpen(false);
    dispatch(projectActions.setUpdateNeeded(true));
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => props.setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={`${styles.formHeader} ${styles.center}`}>
          <Typography className={styles.formHeaderText}>RECORD NEW EXPENSE</Typography>
        </DialogTitle>
        <DialogContent className={styles.formContent}>
          <InputComponent
            label="Note / Description"
            reference={descriptionRef}
            icon={<SearchIcon />}
            placeholder="Expense Details"
            defaultValue={props?.update ? props.expense?.description : null}
          />
          <InputComponent
            label="Amount"
            reference={amountRef}
            icon={<SearchIcon />}
            placeholder="Total Amount"
            defaultValue={props?.update ? props.expense?.amount : null}
          />
          <DatePickerComponent
            label="Start Date"
            icon={<SearchIcon />}
            reference={dateRef}
            defaultValue={props?.update ? props.expense?.updatedAt : null}
            placeholder="Provice Start Date"
          />
          <InputComponent
            label="Payment Mode"
            icon={<SearchIcon />}
            placeholder="Payment Mode / Optional"
            // defaultValue={props?.update ? props.expense?.amount : null}
          />
        </DialogContent>
        <DialogActions className={styles.center}>
          <Box className={styles.buttonContainer}>
            {!loading && (
              <Button onClick={async () => await submit()} variant="contained" className={styles.formButton}>
                {props?.update ? 'Update' : 'Save'}
              </Button>
            )}
            {loading && (
              <Button variant="contained" className={styles.formButton}>
                <CircularProgress size={30} className={styles.circularProgress} />
              </Button>
            )}
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}
