import { Container, Divider, Typography } from '@mui/material';
import ShopLayout from '../../components/layout/Shop';
import CustomersTable from '../../components/Tables/shop/CustomersTable';
import Header from '../../components/UI/others/Header';
import { useSelector } from 'react-redux';
import styles from './index.module.scss';
import TopActions from '../../components/layout/TopActions';
import { useState } from 'react';

const Customers = () => {
  const selectedProject = useSelector((state: any) => state.shop.selectedShopProject);
  const [showFilter, setShowFilter] = useState(false);

  return (
    <ShopLayout select={5}>
      <Typography className={styles.navTitle}>{`${selectedProject?.project?.name} • Customers`}</Typography>
      <TopActions setFilter={setShowFilter} setNew={() => {}} entity="Customer" />
      <Container>{/* <CustomersTable /> */}</Container>
    </ShopLayout>
  );
};

export default Customers;
