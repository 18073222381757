import { Typography } from '@mui/material';
import HomeLayout from '../../components/layout/HomeLayout';

const Expenses = (props: any) => {
  return (
    <HomeLayout select={4}>
      <Typography>Compay Expenses Page, Coming soon..</Typography>
    </HomeLayout>
  );
};

export default Expenses;
