import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRef } from 'react';
import { Button, Typography, Box, CircularProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import styles from '../../index.module.scss';
import { projectActions } from '../../../store/projects';
import axios from 'axios';
import { SERVER_URL } from '../../../utils/constants';

export default function NewImage(props: any) {
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const project = useSelector((state: any) => state.construction.selectedProject);
  const nameRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState(null as unknown);
  const [uploading, setUploading] = useState(false);

  const handleFileInputChange = (e: any) => {
    // @ts-ignore: Object is possibly 'null'.
    const file = (e.target as HTMLInputElement).files[0];
    // previewFile(file);
    setSelectedFile(file);
    // setFileInputState(e.target.value);
  };

  const handleSubmitFile = async (e: any) => {
    try {
      e.preventDefault();
      if (!selectedFile) return;
      let formdata = new FormData();
      formdata.append('file', selectedFile as File);
      if (nameRef.current?.value) formdata.append('name', nameRef.current?.value);
      formdata.append('type', 'image');
      formdata.append('isImage', JSON.stringify(true));
      formdata.append('project', project.id);
      setSaving(true);
      var response = await axios({
        method: 'POST',
        url: `${SERVER_URL}/api/v1/construction/document`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formdata,
      });
      setSaving(false);
      console.log('and the ze response isssiss s  s s s ', response);
      props.setOpen(false);
      dispatch(projectActions.setUpdateNeeded(true));
    } catch (error: any) {
      console.log(error.response.data.message);
    }
  };

  const uploadImage = async (base64EncodedImage: any) => {
    let data: any = { image: base64EncodedImage };
    if (props.category) data.category = props.category;
    if (props.service) data.service = props.service;
    setUploading(true);
    try {
      const image = await axios({
        url: `${SERVER_URL}/api/v1/main/image`,
        method: 'POST',
        data,
      });
      if (props.service) {
        await axios({
          url: `${SERVER_URL}/api/v1/main/service/image-upload/${props.service}`,
          method: 'POST',
          data: {
            image: image.data.data.data._id,
          },
        });
      }
      setUploading(false);
      props.handleClose();
      dispatch(projectActions.setUpdateNeeded(true));
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => props.setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={`${styles.formHeader} ${styles.center}`}>
          <Typography className={styles.formHeaderText}>UPLOAD Image</Typography>
        </DialogTitle>
        <DialogContent className={styles.formContent}>
          <input type="file" id="avatar" onChange={handleFileInputChange} name="avatar" accept="image/*" />
        </DialogContent>
        <DialogActions className={styles.center}>
          <Box className={styles.buttonContainer}>
            {!saving && (
              <Button onClick={handleSubmitFile} variant="contained" className={styles.formButton}>
                UPLOAD
              </Button>
            )}
            {saving && (
              <Button variant="contained" className={styles.formButton}>
                <CircularProgress size={30} className={styles.circularProgress} />
              </Button>
            )}
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}
