import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRef, useEffect, useState } from 'react';
import { Button, Typography, Box, CircularProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SearchIcon from '@mui/icons-material/Search';
import InputComponent from '../Inputs/InputComponent';
import styles from '../../index.module.scss';
import { projectActions } from '../../../store/projects';
import { constructionActions } from '../../../store/construction';
import { addClientService } from '../../../store/construction/constructionService';

export default function NewPartner(props: any) {
  const dispatch = useDispatch();
  // const fetching = useSelector((state: any) => state.project.isFetching);
  const added = useSelector((state: any) => state.construction.added);
  const nameRef = useRef<HTMLInputElement>(null);
  const representativeRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);
  const tinRef = useRef<HTMLInputElement>(null);
  const locationRef = useRef<HTMLInputElement>(null);
  const [saving, setSaving] = useState(false);

  const submit = async () => {
    var data = { name: nameRef.current?.value };
    data = { ...data, ...(representativeRef.current?.value && { representative: representativeRef.current?.value }) };
    data = { ...data, ...(emailRef.current?.value && { email: emailRef.current?.value }) };
    data = { ...data, ...(phoneRef.current?.value && { contact: phoneRef.current?.value }) };
    data = { ...data, ...(tinRef.current?.value && { tinNumber: tinRef.current?.value }) };
    data = { ...data, ...(locationRef.current?.value && { location: locationRef.current?.value }) };
    // if(representativeRef.current?.value) data.representative = representativeRef.current?.value;
    // if(emailRef.current?.value) data.
    // if("ph ", phoneRef.current?.value);
    // if("tom ", tinRef.current?.value);
    // if("loc ", locationRef.current?.value);
    console.log(data);
    // dispatch(projectActions.setIsFetching(true));
    setSaving(true);
    await addClientService(data);
    setSaving(false);
    props.setOpen(false);
    dispatch(projectActions.setUpdateNeeded(true));
    // dispatch(addClient(data));
  };

  // console.log('addedddddd', added);
  // console.log('fetch aa a a aa  aa aa a a a ', fetching);

  // useEffect(() => {
  //   if (added) {
  //     props.setOpen(false);
  //     dispatch(constructionActions.setAdded(false));
  //   }
  // }, [added, dispatch, props]);
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => props.setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={`${styles.formHeader} ${styles.center}`}>
          <Typography className={styles.formHeaderText}>REGISTER NEW CLIENT</Typography>
        </DialogTitle>
        <DialogContent className={styles.formContent}>
          <InputComponent label="Name *" reference={nameRef} icon={<SearchIcon />} placeholder="Enter Project Name" />
          <InputComponent
            label="Represenative"
            reference={representativeRef}
            icon={<SearchIcon />}
            placeholder="Enter Client Representative"
          />
          <InputComponent label="E-mail" reference={emailRef} icon={<SearchIcon />} placeholder="Enter Project Name" />
          <InputComponent
            label="Work Phone Number"
            reference={phoneRef}
            icon={<SearchIcon />}
            placeholder="Primary contact number"
          />
          <InputComponent
            label="Tin Number"
            reference={tinRef}
            icon={<SearchIcon />}
            placeholder="Client's Tin Number"
          />
          <InputComponent
            label="Address"
            reference={locationRef}
            icon={<SearchIcon />}
            placeholder="Enter Office Location"
          />
        </DialogContent>
        <DialogActions className={styles.center}>
          <Box className={styles.buttonContainer}>
            {!saving && (
              <Button onClick={() => submit()} variant="contained" className={styles.formButton}>
                Save
              </Button>
            )}
            {saving && (
              <Button variant="contained" className={styles.formButton}>
                <CircularProgress size={30} className={styles.circularProgress} />
              </Button>
            )}
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}
