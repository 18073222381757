export interface shopPurchaseColumn {
  id: 'date' | 'item' | 'supplier' | 'quantity' | 'unitPrice' | 'totalPrice' | 'totalPaid' | 'action';
  label: String;
  minWidth?: number;
  align?: 'center';
}

export const shopPurchaseColumns: shopPurchaseColumn[] = [
  { id: 'date', label: 'Date', align: 'center' },
  { id: 'item', label: 'Item Name', align: 'center' },
  { id: 'supplier', label: 'Supplier', align: 'center' },
  { id: 'quantity', label: 'Quantity', align: 'center' },
  { id: 'unitPrice', label: 'Unit Price', align: 'center' },
  { id: 'totalPrice', label: 'Total Price', align: 'center' },
  { id: 'totalPaid', label: 'Total Paid', align: 'center' },
  { id: 'action', label: 'Action', align: 'center' },
];

export interface shopPurchaseData {
  id: string;
  date: string;
  item: string;
  supplier: string;
  quantity: string;
  unitPrice: string;
  totalPrice: string;
  totalPaid: string;
  action: any;
}
