import { Routes, Route } from 'react-router-dom';
import Login from '../pages/login';
import Home from '../pages/Home/Dashboard/Dashboard';
import Construction from '../pages/construction/dashboard';
import ConstructionExpenses from '../pages/construction/expenses';
import ConstructionPayments from '../pages/construction/payments';
import ConstructionStock from '../pages/construction/stock/Dashboard';
import Payroll from '../pages/construction/payroll';
import ConstructionDocuments from '../pages/construction/documents/documents';
import ConstructionSettings from '../pages/construction/settings';
import Shop from '../pages/shop/Dashboard';
import Stock from '../pages/shop/Stock';
import Purchases from '../pages/shop/purchases/Purchases';
import Items from '../pages/shop/items/Items';
import Payments from '../pages/shop/Payments';
import Sales from '../pages/shop/sales/Sales';
import Customers from '../pages/shop/Customers';
import UserAccount from '../pages/Home/Account';
import Users from '../pages/Home/Users';
import Expenses from '../pages/Home/Expenses';
import Clients from '../pages/Home/Clients';
import ShopHome from '../pages/Home/Shop/ShopHome';

const MainRouter = () => {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<Home />} />
      <Route path="/construction" element={<Construction />} />
      <Route path="/construction/expenses" element={<ConstructionExpenses />} />
      <Route path="/construction/payments" element={<ConstructionPayments />} />
      <Route path="/construction/stock/:id" element={<ConstructionStock />} />
      <Route path="/construction/payroll" element={<Payroll />} />
      <Route path="/construction/documents" element={<ConstructionDocuments />} />
      <Route path="/construction/settings" element={<ConstructionSettings />} />
      <Route path="/shop/purchases/:id" element={<Purchases />} />
      <Route path="/shop/sales/:id" element={<Sales />} />
      <Route path="/shop/stock/:id" element={<Stock />} />
      <Route path="/shop/customers/:id" element={<Customers />} />
      <Route path="/shop/items/:id" element={<Items />} />
      <Route path="/shop/payments/:id" element={<Payments />} />
      <Route path="/shop" element={<ShopHome />} />
      <Route path="/shop/dashboard/:id" element={<Shop />} />
      <Route path="/home/account" element={<UserAccount />} />
      <Route path="/home/users" element={<Users />} />
      <Route path="/home/expenses" element={<Expenses />} />
      <Route path="/home/clients" element={<Clients />} />
    </Routes>
  );
};

export default MainRouter;
