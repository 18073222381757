import React, { useEffect, useState } from 'react';
import { Tabs, Tab, Typography, Box, Divider, Grid, Button, Stack } from '@mui/material';
import HomeLayout from '../../components/layout/HomeLayout';
import UsersTable from './Users/UsersTable';
import RoleTable from './Roles/RolesTable';
import RoleList from './Roles/RolesList';
import Privileges from './Roles/Privileges';
const Users = (props: any) => {
  const [activeTab, setActiveTab] = useState(props.tabIndex || 0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };
  return (
    <HomeLayout select={3}>
      <Box sx={{ height: 100 }}>
        <Typography>USERS</Typography>
      </Box>
      <Box sx={{ background: '#Red' }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          // variant="fullWidth"
          scrollButtons={false}
          aria-label="scrollable prevent tabs example"
        >
          <Tab label="Users" />
          <Tab label="Roles" />
        </Tabs>
      </Box>
      <Divider />
      <Divider sx={{ height: 20 }} />
      <Box>
        {activeTab === 0 && (
          <>
            <UsersTable></UsersTable>
          </>
        )}
        {activeTab === 1 && (
          <>
            <Grid container spacing={2}>
              <Grid item xs={5}>
                <Stack spacing={5} direction="column">
                  <Stack direction="row" justifyContent="space-around" alignItems="center" spacing={2}>
                    <h3>User Roles</h3>
                    <Button variant="contained">Create New Role</Button>
                  </Stack>
                  {/* <RoleTable></RoleTable> */}
                  <RoleList/>
                </Stack>
              </Grid>
              <Grid item xs={7}>
              <Stack spacing={5} direction="column">
                  <Stack direction="row" justifyContent="space-around" alignItems="center" spacing={2}>
                    <h3>Priveleges</h3>
                    <Button variant="contained">Create New Privelege</Button>
                  </Stack>
                <Privileges />
                </Stack>
              </Grid>
            </Grid>
          </>
        )}
      </Box>
    </HomeLayout>
  );
};

export default Users;
