import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getExpenses, deleteExpense } from '../../../store/construction/constructionActions';
import { projectActions } from '../../../store/projects';
import { Avatar, Typography, Menu, MenuItem, Box, CircularProgress } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import VisibilityIcon from '@mui/icons-material/Visibility';
import TableComponent from '../../UI/others/TableComponent';
import { expenseColumns, expenseData } from '../../../interfaces/expense';
import { deleteRed, primaryGreen, primaryPurple } from '../../../assets/colors/colors';
import ConfirmModal from '../../UI/others/ConfirmModal';
import styles from '../../../pages/Home/index.module.scss';
import { numberWithCommas } from '../../../utils/functions';

import UpdateExpenseModal from '../../../components/UI/Forms/newExpense';

const ExpensesTable = (props: any) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(25);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [expenseId, setExpenseId] = useState('');
  const [expenseSelected, setExpenseSelected] = useState({});
  const [openExpenseModal, setOpenExpenseModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const constructionState = useSelector((state: any) => state.construction);
  const updateNeeded = useSelector((state: any) => state.project.updateNeeded);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = async () => {
    await deleteExpense(expenseId);
    dispatch(projectActions.setUpdateNeeded(true));
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (props.id) dispatch(getExpenses(props.id, `page=${page}&limit=${rowsPerPage}&sort=-date`));
  }, [props.id, dispatch]);

  useEffect(() => {
    console.log('updatedNedded changed ', updateNeeded);
    if (updateNeeded) dispatch(getExpenses(props.id, `page=${page}&limit=${rowsPerPage}&sort=-date`));
  }, [updateNeeded, dispatch]);

  const createData = (id: string, description: string, amount: number, date: string): expenseData => {
    return {
      id,
      description,
      amount: numberWithCommas(amount),
      date,
      action: (
        <button
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setExpenseId(id);
            setExpenseSelected({ id, description, amount, updatedAt: date, project: props.id });
            handleClick(event);
          }}
          className={styles.actionsIcon}
        >
          •••
        </button>
      ),
    };
  };

  const rows = constructionState?.expenses?.map((expense: any) =>
    createData(expense._id, expense.description, expense.amount, expense.date.substring(0, 10))
  );

  console.log('rebuilt, the expense rows are ...', updateNeeded, rows);

  return (
    <>
      {constructionState?.fetching && (
        <Box className={styles.loadingContainer}>
          <CircularProgress />
        </Box>
      )}

      {!constructionState?.fetching && (
        <TableComponent
          rows={rows}
          columns={expenseColumns}
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          rowClickedHandler={() => {}}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
        />
      )}
      {openExpenseModal && (
        <UpdateExpenseModal
          open={openExpenseModal}
          project={props.id}
          update={true}
          expense={expenseSelected}
          setOpen={setOpenExpenseModal}
        />
      )}
      {open && (
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem>
            <VisibilityIcon />
            <Typography> View</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              console.log('SssssssS:', expenseSelected);
              setOpenExpenseModal(true);
              handleClose();
            }}
          >
            <AutorenewIcon />
            <Typography> Update</Typography>
          </MenuItem>
          <MenuItem
            onClick={async () => {
              await handleDelete();
              handleClose();
            }}
          >
            <DeleteIcon /> <Typography> Delete</Typography>
          </MenuItem>
        </Menu>
      )}
      {showModal && (
        <ConfirmModal
          open={showModal}
          onCancel={() => setShowModal(false)}
          setOpen={setShowModal}
          content={`Are you sure you want to Delete ${expenseId} Expnse`}
        />
      )}
    </>
  );
};

export default ExpensesTable;
