import { Typography } from '@mui/material';
import ShopLayout from '../../../components/layout/Shop';
import styles from '../index.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import SalesTable from './SalesTable';

const Sales = () => {
  const shopState = useSelector((state: any) => state.shop);

  return (
    <ShopLayout select={3}>
      <Typography className={styles.navTitle}>{`${shopState?.selectedShop?.project?.name} • SALES`}</Typography>
      <SalesTable />
    </ShopLayout>
  );
};

export default Sales;
