import { Box, Typography, Input } from "@mui/material";
import styles from "../../../pages/Home/index.module.scss";

const InputComponent = (props: any) => {
    return (
        <Box className={styles.inputComponent}>
            <Typography className={styles.label}
            >
                {props.label}
            </Typography>
            <Input
                inputRef={props?.reference}
                placeholder={props.placeholder}
                disableUnderline={true}
                className={styles.input}
                defaultValue={props?.defaultValue}
            />
        </Box>
    );
};

export default InputComponent;
