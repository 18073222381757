import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import authReducer from './auth';
import projectsReducer from './projects/index';
import shopProjectReducer from './shop';
import constructionProjectReducer from './construction';

const store = configureStore({
  reducer: {
    auth: authReducer,
    project: projectsReducer,
    shop: shopProjectReducer,
    construction: constructionProjectReducer,
  },
});

export default store;
