import ConstructionLayout from "../../components/layout/Construction";

const ConstructionPayments = () => {
    return (
        <ConstructionLayout select={3}>
            <h4>Construction Payments</h4>
        </ConstructionLayout>
    );
}

export default ConstructionPayments;