import { createSlice } from '@reduxjs/toolkit';

const projectSlice = createSlice({
  name: 'projects',
  initialState: {
    updateNeeded: true,
    isFetching: false,
    selectedProject: {},
    projectId: '',
    count: 0,
    projects: [],
    categories: [],
  },
  reducers: {
    changeSelectedProject(state, action) {
      state.selectedProject = action.payload.project;
    },
    setProjectId(state, action) {
      state.projectId = action.payload;
    },
    setUpdateNeeded(state, action) {
      state.updateNeeded = action.payload;
    },
    setIsFetching(state, action) {
      state.isFetching = action.payload;
    },
    setCategories(state, action) {
      state.categories = action.payload.categories;
    },
    setCount(state, action) {
      state.count = action.payload.count;
    },
    setProject(state, action) {
      state.projects = action.payload.projects;
      state.updateNeeded = false;
      state.isFetching = false;
    },
  },
});

export const projectActions = projectSlice.actions;

export default projectSlice.reducer;
