import { useState } from 'react';
import { Typography } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ConstructionLayout from '../../../components/layout/Construction';
import styles from './../index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import PurchaseOrders from './PurchaseOrders';
import ConstructionImages from './Images';

const ConstructionDocuments = () => {
  // const project = useSelector((state: any) => state.construction.selectedProject);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const constructiontState = useSelector((state: any) => state.construction);
  const projectState = useSelector((state: any) => state.project);
  const dispatch = useDispatch();

  const handleListItemClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
    setSelectedIndex(index);
  };

  console.log('docs updateNNNN', projectState.updateNeeded);

  // useEffect(() => {
  //   if (projectState.updateNeeded) dispatch(getDocuments(constructiontState?.selectedProject.id));
  // }, [dispatch, constructiontState?.selectedProject.id, projectState.updateNeeded]);

  return (
    <ConstructionLayout select={4}>
      <Typography className={styles.navTitle}>{`${constructiontState.selectedProject.name} • Documents`}</Typography>

      <List className={styles.foldersContainer}>
        <ListItem
          selected={0 === selectedIndex}
          onClick={(event: any) => handleListItemClick(event, 0)}
          classes={{ root: styles.root, selected: styles.listItemSelected }}
        >
          <ListItemText className={styles.documentCategory}>Purchase Order, Contracts, Letters</ListItemText>
        </ListItem>
        <ListItem
          selected={1 === selectedIndex}
          onClick={(event: any) => handleListItemClick(event, 1)}
          classes={{ root: styles.root, selected: styles.listItemSelected }}
        >
          <ListItemText className={styles.documentCategory}>Images</ListItemText>
        </ListItem>
      </List>
      {selectedIndex === 0 && <PurchaseOrders />}
      {selectedIndex === 1 && <ConstructionImages />}
    </ConstructionLayout>
  );
};

export default ConstructionDocuments;
