import ConstructionLayout from '../../components/layout/Construction';
import { useSelector } from 'react-redux';
import ExpensesTables from '../../components/Construction/Project/ExpensesTable';
import Banner from '../../components/Construction/Banner';
import { Typography } from '@mui/material';
import styles from './index.module.scss';

const Construction = () => {
  const project = useSelector((state: any) => state.construction.selectedProject);

  return (
    <ConstructionLayout select={1}>
      <Typography className={styles.navTitle}>{`${project.name} • Dashboard`}</Typography>
      <Banner id={project.id} />
      <ExpensesTables id={project.id} />
    </ConstructionLayout>
  );
};

export default Construction;
