import axios from 'axios';
import { shopActions } from '..';
import { LooseObject } from '../../../interfaces/constants';
import { SERVER_URL } from '../../../utils/constants';
import { projectActions } from '../../projects';

//CRUD Actions for Shop Items

// CREATE ONE
export const addItem = (data: LooseObject, token: string) => {
  return async (dispatch: any) => {
    try {
      const res = await axios({
        method: 'POST',
        url: `${SERVER_URL}/api/v1/shop/items`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data,
      });
      console.log('doneeeeeeeeeeeeeeeeeeeeeeeeee');
      dispatch(projectActions.setIsFetching(false));
      dispatch(projectActions.setUpdateNeeded(true));
    } catch (err) {
      console.log(err);
      dispatch(projectActions.setIsFetching(false));
    }
  };
};

// GET ALL
export const getShopItems = (query: string, token: string) => {
  return async (dispatch: any) => {
    try {
      const res = await axios({
        method: 'GET',
        url: `${SERVER_URL}/api/v1/shop/items?${query}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      dispatch(shopActions.setItems(res.data.data.data));
      dispatch(projectActions.setCount({ count: res.data.count }));
      dispatch(projectActions.setIsFetching(false));
      dispatch(projectActions.setUpdateNeeded(false));
    } catch (err) {
      console.log(err);
    }
  };
};

// DELETE One
export const deleteItem = (itemId: string, token: String) => {
  return async (dispatch: any) => {
    try {
      const res = await axios({
        method: 'DELETE',
        url: `${SERVER_URL}/api/v1/shop/items/${itemId}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch(projectActions.setIsFetching(false));
      // dispatch(shopActions.setPurchases({shopProject: res.data.data}));
    } catch (err) {
      console.log(err);
      dispatch(projectActions.setIsFetching(false));
    }
  };
};
