export const primaryColor = '#1987FF';
export const primaryGrey = '#C4C4C4';
export const secondaryRedOrange = '#F6511D';
export const thirdYellow = '#FFB400';
export const fourthAppleGreen = '#7FB800';
export const fifthDarkBlue = '#0D2C54';
export const textColor = '#545252';

export const lightBlue = '#9ac7fd';
export const darkBlue = '#4084d8';
export const blueBackground = '#e8f2fc';
export const lightOrange = '#fccaa1';
export const darkOrange = '#e77111';

export const primaryPurple = '#2C358D';
export const primaryGreen = '#00A44B';
export const primaryOrange = '#ED7D3A';

export const primaryGrey2 = '#F8F8F8';
export const primaryBackground = '#F5F5F5';
export const primaryTextColor = '#9E9E9E';
// export const primaryBlue = "#26AAE1";
export const primaryBlue = '#4084d8';
export const primaryRed = '#EF2D56';
export const deleteRed = '#ED5E68';
export const deleteRed2 = '#F9320D';
