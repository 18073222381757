import axios from 'axios';
import store from 'store';
import { authActions } from '.';
import { SERVER_URL } from '../../utils/constants';
import { loginService } from './authServices';

export const loginAction = (data: {}) => {
  return async (dispatch: any) => {
    dispatch(authActions.setIsFetching(true));
    try {
      dispatch(authActions.login({ status: null }));
      const res = await loginService(data);
      if (res?.status === 200) {
        store.set('x-auth-token', res?.data?.token);
        store.set('user', res?.data?.data?.user);
      }
      dispatch(authActions.setIsFetching(false));
      dispatch(authActions.login({ ...res?.data, status: res?.status }));
    } catch (err) {
      console.log(err);
    }
  };
};
