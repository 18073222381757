import React, { useEffect, useState } from 'react';
import styles from '../index.module.scss';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import TableComponent from '../../../components/UI/others/TableComponent';
import { Menu, MenuItem, Typography } from '@mui/material';
import ProjectView from '../../Home/Dashboard/ProjectView';
import { useDispatch, useSelector } from 'react-redux';
import { shopPurchaseColumns, shopPurchaseData } from '../../../interfaces/shop/shopPurchase';
import LoadingContainer from '../../../components/UI/others/LoadingContainer';
import { projectActions } from '../../../store/projects';
import TopActions from '../../../components/layout/TopActions';
import { useParams } from 'react-router-dom';
import { getShopPurchases, searchShopPurchases } from '../../../store/shop/shopActions/Purchases';
import NewShopPurchase from '../../../components/UI/Forms/NewPurchase';

function createData(date: string, item: string, quantity: number, totalPrice: number) {
  return { date, item, quantity, totalPrice };
}

export default function PurchasesTable(props: any) {
  const [showModal, setShowModal] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [projectViewOpen, setProjectViewOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(25);
  const [searchText, setSearchText] = useState('');
  const [newPurchase, setNewPurchase] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const shopState = useSelector((state: any) => state.shop);
  const projectState = useSelector((state: any) => state.project);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.auth.token);

  let { id } = useParams();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const createData = (
    id: string,
    date: string,
    item: string,
    supplier: string,
    quantity: string,
    unitPrice: string,
    totalPrice: string,
    totalPaid: string
  ): shopPurchaseData => {
    return {
      id,
      date,
      item,
      supplier,
      quantity,
      unitPrice,
      totalPrice,
      totalPaid,
      action: (
        <button onClick={(event: React.MouseEvent<HTMLButtonElement>) => {}} className={styles.actionsIcon}>
          •••
        </button>
      ),
    };
  };

  const handleCloseAction = () => {
    handleClose();
    setShowModal(true);
  };

  const handleEditAction = () => {
    handleClose();
    setProjectViewOpen(true);
  };

  const handleDeleteAction = () => {
    setAnchorEl(null);
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
    dispatch(projectActions.setUpdateNeeded(true));
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const rows = shopState?.shopPurchases?.map((project: any) =>
    createData(
      project._id,
      project.date?.substring(0, 10),
      project.item?.name,
      project.supplier?.name,
      project?.quantity,
      project?.unitPrice,
      project?.totalPrice,
      project?.totalPaid
    )
  );

  useEffect(() => {
    if (!projectState.isFetching) {
      setCount(projectState.count);
    }
  }, [projectState.isFetching, projectState.count]);

  useEffect(() => {
    let queryString = '';
    queryString += `&sort=-date`;
    setQuery(queryString);
    dispatch(projectActions.setUpdateNeeded(true));
  }, []);

  useEffect(() => {
    if (projectState.updateNeeded) {
      dispatch(projectActions.setIsFetching(true));
      if (id) dispatch(getShopPurchases(`project=${id}&page=${page}&limit=${rowsPerPage}${query}`, token));
    }
  }, [id, page, rowsPerPage, query, token, projectState.updateNeeded]);

  useEffect(() => {
    let timer = setTimeout(() => {
      if (searchText) {
        dispatch(projectActions.setIsFetching(true));
        dispatch(searchShopPurchases(`?name[regex]=${searchText}&project=${id}`, token));
      } else {
        if (!projectState.isFetching && !projectState.updateNeeded) {
          dispatch(projectActions.setUpdateNeeded(true));
        }
      }
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [searchText]);

  return (
    <>
      <TopActions
        setFilter={setShowFilter}
        setNew={() => {
          setNewPurchase(true);
        }}
        entity="Purchase"
        setSearchText={setSearchText}
      />
      {!projectState.isFetching && (
        <>
          {rows?.length > 0 && (
            <TableComponent
              rows={rows}
              columns={shopPurchaseColumns}
              rowClickedHandler={() => {}}
              count={count}
              page={page}
              rowsPerPage={rowsPerPage}
              handlePageChange={handlePageChange}
              handleRowsPerPageChange={handleRowsPerPageChange}
            />
          )}
          {open && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={handleEditAction}>
                <AutorenewIcon />
                <Typography>Edit</Typography>
              </MenuItem>
              <MenuItem onClick={handleCloseAction}>
                <CloseIcon />
                <Typography>Close</Typography>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <DeleteIcon />
                <Typography>Delete</Typography>
              </MenuItem>
            </Menu>
          )}
          {projectViewOpen && <ProjectView open={projectViewOpen} setOpen={setProjectViewOpen} />}
        </>
      )}
      {projectState.isFetching && <LoadingContainer />}
      {newPurchase && <NewShopPurchase open={newPurchase} setOpen={setNewPurchase} />}
    </>
  );
}