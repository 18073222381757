import React, { useEffect, useState } from 'react';
import { Grid, Backdrop, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import store from 'store';
import { authActions } from '../../store/auth';

import logo from '../../assets/images/logocems.png';
// import logo from '../../assets/images/logo.png';
import styles from '../../pages/Home/index.module.scss';

import ConstructionNavMenu from '../NavMenu/ConstructionNavMenu';

const ConstructionLayout = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authToken = store.get('x-auth-token');
  const authUser = store.get('user');

  const authState = useSelector((state: any) => state.auth);

  useEffect(() => {
    if (!authToken || !authUser) {
      navigate('/login');
    } else if (!authState.isAuth) {
      dispatch(authActions.login({ token: authToken, data: { user: authUser }, status: 200 }));
    }
  }, [dispatch, authState]);

  return (
    <>
      {(!authToken || !authUser || !authState.isAuth) && (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Grid container className={styles.container} spacing={0}>
        <Grid className={styles.sidebar} item xs={2}>
          <div className={`${styles.center} ${styles.imageContainer}`}>
            <img className={styles.image} alt="logo" src={logo} />
          </div>
          <ConstructionNavMenu select={props.select} />
        </Grid>
        <Grid className={styles.main} item xs={10}>
          {props.children}
        </Grid>
      </Grid>
    </>
  );
};

export default ConstructionLayout;
