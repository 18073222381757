import React, { useEffect, useState } from 'react';
import { Grid, Backdrop, CircularProgress, Box, InputBase, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import store from 'store';
import { authActions } from '../../store/auth';

import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import DescriptionIcon from '@mui/icons-material/Description';
import styles from '../../pages/Home/index.module.scss';
import XLSX from 'xlsx';

const exportExcel = (Dataset: any, fileName: String) => {
  /* Create a worksheet */
  var ws = XLSX.utils.json_to_sheet(Dataset);

  /* Create a new empty workbook, then add the worksheet */
  var wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Test');

  /* Generate xlsx files */
  XLSX.writeFile(wb, `${fileName}s.xlsx`);
};

const TopActions = (props: any) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const authToken = store.get('x-auth-token');
  const authUser = store.get('user');

  const authState = useSelector((state: any) => state.auth);

  useEffect(() => {
    if (!authToken || !authUser) {
      navigate('/login');
    } else if (!authState.isAuth) {
      dispatch(authActions.login({ token: authToken, data: { user: authUser }, status: 200 }));
    }
  }, [dispatch, authState]);

  return (
    <>
      {(!authToken || !authUser || !authState.isAuth) && (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <Box className={styles.topActions}>
        <Box className={styles.topActionsLeft}>
          <div className={styles.search}>
            <div className={styles.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search..."
              classes={{
                root: styles.inputRoot,
                input: styles.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
              onChange={(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                props.setSearchText(event.target.value);
              }}
            />
          </div>
          <Button variant="contained" className={styles.normalButton} onClick={() => props.setFilter(true)}>
            Filter
          </Button>
        </Box>
        <Box className={styles.topActionsRight}>
          <Button
            variant="contained"
            startIcon={<DescriptionIcon />}
            className={styles.normalButton}
            onClick={() => exportExcel(props?.data, props?.entity)}
          >
            Export Excel
          </Button>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            className={styles.newButton}
            onClick={() => props.setNew(true)}
          >
            Add New {props?.entity}
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default TopActions;
