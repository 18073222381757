import { Box, Typography, Input } from '@mui/material';
import styles from '../../index.module.scss';

const FormInput = (props: any) => {
  return (
    <Box className={styles.forms_inputComponent}>
      <Typography className={styles.label}>{props.label}</Typography>
      <Input
        inputRef={props?.reference}
        placeholder={props.placeholder}
        disableUnderline={true}
        className={styles.form_input}
        defaultValue={props?.defaultValue}
      />
    </Box>
  );
};

export default FormInput;
