import React, { useEffect, useState } from 'react';
import styles from '../index.module.scss';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import TableComponent from '../../../components/UI/others/TableComponent';
import { Menu, MenuItem, Typography } from '@mui/material';
import ProjectView from '../../Home/Dashboard/ProjectView';
import { useDispatch, useSelector } from 'react-redux';
import LoadingContainer from '../../../components/UI/others/LoadingContainer';
import { projectActions } from '../../../store/projects';
import TopActions from '../../../components/layout/TopActions';
import { useParams } from 'react-router-dom';
import NewSale from './CreateSale';
import { saleExportData, shopSaleColumns, shopSaleData } from '../../../interfaces/shop/sales';
import { getShopSales, searchShopSales } from '../../../store/shop/shopActions';

function createData(date: string, item: string, quantity: number, totalPrice: number) {
  return { date, item, quantity, totalPrice };
}

export default function SalesTable(props: any) {
  const [showModal, setShowModal] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [projectViewOpen, setProjectViewOpen] = useState(false);
  const [query, setQuery] = useState('');
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(25);
  const [searchText, setSearchText] = useState('');
  const [newSale, setNewSale] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const shopState = useSelector((state: any) => state.shop);
  const projectState = useSelector((state: any) => state.project);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const token = useSelector((state: any) => state.auth.token);

  let { id } = useParams();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const createData = (
    id: string,
    date: string,
    customer: string,
    item: string,
    manufacturer: string,
    quantity: string,
    buyingPrice: string,
    sellingPrice: string,
    totalPrice: string,
    totalPaid: string,
    profit: string
  ): shopSaleData => {
    return {
      id,
      date,
      customer,
      item,
      manufacturer,
      quantity,
      buyingPrice,
      sellingPrice,
      totalPrice,
      totalPaid,
      profit,
      action: (
        <button onClick={(event: React.MouseEvent<HTMLButtonElement>) => {}} className={styles.actionsIcon}>
          •••
        </button>
      ),
    };
  };

  const handleCloseAction = () => {
    handleClose();
    setShowModal(true);
  };

  const handleEditAction = () => {
    handleClose();
    setProjectViewOpen(true);
  };

  const handleDeleteAction = () => {
    setAnchorEl(null);
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
    dispatch(projectActions.setUpdateNeeded(true));
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createExportableData = (
    date: string,
    customer: string,
    item: string,
    quantity: number,
    buyingPrice: number,
    totalCost: number,
    sellingPrice: number,
    totalPrice: number,
    totalPaid: number,
    profit: number
  ): saleExportData => {
    return {
      date,
      customer,
      item,
      quantity,
      buyingPrice,
      totalCost,
      sellingPrice,
      totalPrice,
      totalPaid,
      profit,
    };
  };

  // "quantity": 15,
  //               "unitprice": 6090,
  //               "buyingPrice": 5655,
  //               "totalPaid": 91350,

  const data = shopState?.shopSales?.map((sale: any) =>
    createExportableData(
      sale.date.substring(0, 10),
      sale.customer.name,
      sale.item.name,
      sale.quantity,
      sale.buyingPrice,
      sale.quantity * sale.buyingPrice,
      sale.unitprice,
      sale.quantity * sale.unitprice,
      sale.totalPaid,
      sale.quantity * (sale.unitprice - sale.buyingPrice)
    )
  );

  const rows = shopState?.shopSales?.map((sale: any) =>
    createData(
      sale._id,
      sale.date?.substring(0, 10),
      sale.customer?.name,
      sale.item?.name,
      sale.manufacturer?.name,
      sale?.quantity,
      sale?.buyingPrice,
      sale?.unitprice,
      sale?.totalPrice,
      sale?.totalPaid,
      sale?.profit
    )
  );

  useEffect(() => {
    if (!projectState.isFetching) {
      setCount(projectState.count);
    }
  }, [projectState.isFetching, projectState.count]);

  useEffect(() => {
    let queryString = '';
    queryString += `&sort=-date`;
    setQuery(queryString);
    dispatch(projectActions.setUpdateNeeded(true));
  }, []);

  useEffect(() => {
    if (projectState.updateNeeded) {
      dispatch(projectActions.setIsFetching(true));
      if (id) dispatch(getShopSales(`project=${id}&page=${page}&limit=${rowsPerPage}${query}`, token));
    }
  }, [id, page, rowsPerPage, query, token, projectState.updateNeeded]);

  useEffect(() => {
    console.log('searchhhh ', searchText);
    let timer = setTimeout(() => {
      if (searchText) {
        dispatch(projectActions.setIsFetching(true));
        dispatch(searchShopSales(`?name=${searchText}&project=${id}`, token));
      } else {
        if (!projectState.isFetching && !projectState.updateNeeded) {
          dispatch(projectActions.setUpdateNeeded(true));
        }
      }
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [searchText]);

  return (
    <>
      <TopActions
        setFilter={setShowFilter}
        setNew={() => {
          setNewSale(true);
        }}
        data={data}
        entity="Sale"
        setSearchText={setSearchText}
        addNew={true}
      />
      {!projectState.isFetching && (
        <>
          <TableComponent
            rows={rows}
            columns={shopSaleColumns}
            rowClickedHandler={() => {}}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
          />
          {open && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={handleEditAction}>
                <AutorenewIcon />
                <Typography>Edit</Typography>
              </MenuItem>
              <MenuItem onClick={handleCloseAction}>
                <CloseIcon />
                <Typography>Close</Typography>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <DeleteIcon />
                <Typography>Delete</Typography>
              </MenuItem>
            </Menu>
          )}
          {projectViewOpen && <ProjectView open={projectViewOpen} setOpen={setProjectViewOpen} />}
        </>
      )}
      {projectState.isFetching && <LoadingContainer />}
      {newSale && <NewSale />}
    </>
  );
}
