import axios from 'axios';

import { projectActions } from '.';
import { SERVER_URL } from '../../utils/constants';

export const getShopProjectPurchases = () => {};

export const getProjects = () => {
  return async (dispatch: any) => {
    const sendRequest = async () => {
      const response = await axios({
        method: 'GET',
        url: `${SERVER_URL}/api/v1/project/projects`,
      });
      return response;
    };

    try {
      const res = await sendRequest();
      // dispatch(projectActions.setUpdateNeeded(false));
      dispatch(projectActions.setProject({ projects: res.data.data.data }));
    } catch (err: any) {
      console.log(err.response.data.message);
    }
  };
};

export const getProjectCategories = () => {
  return async (dispatch: any) => {
    try {
      const res = await axios({
        method: 'GET',
        url: `${SERVER_URL}/api/v1/project/projectcategory`,
      });
      dispatch(projectActions.setCategories({ categories: res.data.data.data }));
    } catch (err) {
      console.log(err);
    }
  };
};

export const createProject = async (data: any) => {
  try {
    const response = await axios({
      method: 'POST',
      url: `${SERVER_URL}/api/v1/project/projects`,
      data: {
        name: data.name,
        category: data.category,
      },
    });
    return response.data.data.data._id;
  } catch (err: any) {
    console.log(err.response.data.message);
    console.log('errorrrr ', err);
  }
};
