import { createSlice } from '@reduxjs/toolkit';

const shopSlice = createSlice({
  name: 'shopProject',
  initialState: {
    shopProjects: [],
    selectedShop: {},
    shopPurchases: [],
    shopSales: [],
    shopCustomers: [],
    manufacturers: [],
    shopSuppliers: [],
    shopStock: [],
    shopItems: [],
  },
  reducers: {
    setProjects(state, action) {
      state.shopProjects = action.payload;
    },
    setSelectedShop(state, action) {
      state.selectedShop = action.payload;
    },
    setPurchases(state, action) {
      state.shopPurchases = action.payload;
    },
    setSales(state, action) {
      state.shopSales = action.payload;
    },
    setCustomers(state, action) {
      state.shopCustomers = action.payload;
    },
    setManufacturers(state, action) {
      state.manufacturers = action.payload;
    },
    setItems(state, action) {
      state.shopItems = action.payload;
    },
  },
});

export const shopActions = shopSlice.actions;

export default shopSlice.reducer;
