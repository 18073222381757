import { List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { Link } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import HomeIcon from '@mui/icons-material/Home';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import styles from './index.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { darkBlue, textColor } from '../../assets/colors/colors';

const NavMenu = (props: any) => {
  const { select } = props;

  const currentShopProject = useSelector((state: any) => state.shop?.selectedShop);
  const NavItems = [
    { label: 'Home', icon: <HomeIcon style={{ color: 'currentColor' }} />, path: '/' },
    { label: 'Dashboard', icon: <DashboardIcon style={{ color: 'currentColor' }} />, path: '/shop' },
    {
      label: 'Purchases',
      icon: <AddShoppingCartIcon style={{ color: 'currentColor' }} />,
      path: `/shop/purchases/${currentShopProject?._id}`,
    },
    {
      label: 'Sales',
      icon: <ShoppingCartIcon style={{ color: 'currentColor' }} />,
      path: `/shop/sales/${currentShopProject?._id}`,
    },
    {
      label: 'Stock',
      icon: <ShowChartIcon style={{ color: 'currentColor' }} />,
      path: `/shop/stock/${currentShopProject?._id}`,
    },
    {
      label: 'Customers',
      icon: <PeopleAltIcon style={{ color: 'currentColor' }} />,
      path: `/shop/customers/${currentShopProject?._id}`,
    },
    {
      label: 'Items',
      icon: <Inventory2Icon style={{ color: 'currentColor' }} />,
      path: `/shop/items/${currentShopProject?._id}`,
    },
    {
      label: 'Payments',
      icon: <AttachMoneyIcon style={{ color: 'currentColor' }} />,
      path: `/shop/payments/${currentShopProject?._id}`,
    },
  ];
  return (
    <List>
      {NavItems.map((nav, index) => (
        <Link key={index} to={nav.path} className={styles.link}>
          <ListItem selected={index === select} classes={{ root: styles.root, selected: styles.listItemSelected }}>
            <ListItemIcon style={{ color: index === select ? darkBlue : textColor }}>{nav.icon}</ListItemIcon>
            <ListItemText>{nav.label}</ListItemText>
          </ListItem>
        </Link>
      ))}
    </List>
  );
};

export default NavMenu;
