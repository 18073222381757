import { Typography, MenuItem, Box } from '@mui/material';
import ShopLayout from '../../../components/layout/Shop';
import styles from '../index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getShopManufacturers } from '../../../store/shop/shopActions';
import LoadingContainer from '../../../components/UI/others/LoadingContainer';
import { projectActions } from '../../../store/projects';
import ItemsTopActions from '../../../components/layout/ItemsTopActions';
import GenericForm from '../../../components/UI/Forms/GenericForm';
import SelectComponent from '../../../components/UI/Inputs/SelectComponent';
import ItemsTable from './itemsTable';
import { addItem, getShopItems } from '../../../store/shop/shopActions/Items';

const Items = () => {
  const [showModal, setShowModal] = useState(false);
  const [projectViewOpen, setProjectViewOpen] = useState(false);
  const nameRef = useRef<HTMLInputElement>(null);
  const buyingPriceRef = useRef<HTMLInputElement>(null);
  const sellingPriceRef = useRef<HTMLInputElement>(null);
  const [manufacturer, setManufacturer] = useState('');
  const [searchText, setSearchText] = useState('');
  const manufacturers = useSelector((state: any) => state.shop.manufacturers);
  const token = useSelector((state: any) => state.auth.token);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(25);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [newItem, setNewItem] = useState(false);
  const [query, setQuery] = useState('');
  const shopState = useSelector((state: any) => state.shop);
  const projectState = useSelector((state: any) => state.project);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();

  let { id } = useParams();

  const newItemFormElements = [
    {
      label: 'Manufacturer',
      type: 'select',
      value: manufacturer,
      values: manufacturers,
      setValue: setManufacturer,
      id: '_id',
      name: 'name',
    },
    { label: 'Item Name', type: 'input', reference: nameRef, placeholder: 'Enter Item Name' },
    { label: 'Buying Price', type: 'input', reference: buyingPriceRef, placeholder: 'Price yo kurangura' },
    { label: 'Selling Price', type: 'input', reference: sellingPriceRef, placeholder: 'Price yo gucuruza' },
  ];

  const addItemHandler = () => {
    setNewItem(false);
    const data = {
      name: nameRef.current?.value,
      buyingprice: buyingPriceRef.current?.value,
      sellingprice: sellingPriceRef.current?.value,
      manufacturer,
      project: id,
    };
    dispatch(projectActions.setIsFetching(true));
    dispatch(addItem(data, token));
  };

  useEffect(() => {
    if (!projectState.isFetching) {
      setCount(projectState.count);
    }
  }, [projectState.isFetching, projectState.count]);

  useEffect(() => {
    let timer = setTimeout(() => {
      console.log("let's search");
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, [searchText]);

  useEffect(() => {
    let queryString = '';
    queryString += `&manufacturer=${manufacturer}`;
    setQuery(queryString);
    dispatch(projectActions.setUpdateNeeded(true));
  }, [manufacturer]);

  useEffect(() => {
    if (projectState.updateNeeded) {
      dispatch(projectActions.setIsFetching(true));
      dispatch(getShopManufacturers(`project=${id}`, token));
      if (id) dispatch(getShopItems(`project=${id}&page=${page}&limit=${rowsPerPage}&active=true${query}`, token));
    }
  }, [id, page, rowsPerPage, query, token, projectState.updateNeeded]);

  return (
    <ShopLayout select={6}>
      <Typography className={styles.navTitle}>{`${shopState?.selectedShop?.project?.name} • ITEMS`}</Typography>
      <ItemsTopActions
        token={token}
        setNew={() => {
          setNewItem(true);
        }}
        project={id}
        entity="Item"
      />
      <Box className={styles.itemsCategoryBox}>
        <Typography>Category: </Typography>
        <SelectComponent value={manufacturer} values={manufacturers} setValue={setManufacturer} id="_id" name="name" />
      </Box>
      {projectState.isFetching && <LoadingContainer />}
      {!projectState.isFetching && <ItemsTable />}
      {newItem && (
        <GenericForm
          title="New Item"
          onSubmitted={addItemHandler}
          formElements={newItemFormElements}
          open={newItem}
          setOpen={setNewItem}
        />
      )}
    </ShopLayout>
  );
};

export default Items;
