import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRef, useEffect } from 'react';
import { Button, Typography, Box, CircularProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SearchIcon from '@mui/icons-material/Search';
import InputComponent from '../Inputs/InputComponent';
import styles from '../../index.module.scss';
import { projectActions } from '../../../store/projects';
import axios from 'axios';
import SelectComponent from '../Inputs/SelectComponent';
import { SERVER_URL } from '../../../utils/constants';

export default function NewDocument(props: any) {
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const [type, setType] = useState('');
  const project = useSelector((state: any) => state.construction.selectedProject);
  const nameRef = useRef<HTMLInputElement>(null);

  let typeValues = [
    {
      name: 'Purchase Order',
      _id: 'purchaseorder',
    },
    {
      name: 'Contract',
      _id: 'contract',
    },
    {
      name: 'Letter',
      _id: 'letter',
    },
    {
      name: 'Invoice',
      _id: 'invoice',
    },
    {
      name: 'Other',
      _id: 'other',
    },
  ];

  // const [fileInputState, setFileInputState] = useState("");
  // const [previewSource, setPreviewSource] = useState("");
  const [selectedFile, setSelectedFile] = useState(null as unknown);
  const [uploading, setUploading] = useState(false);

  const handleTypeChange = (event: any) => {
    setType(event.target.value as string);
  };

  // console.log('addedddddd', added);
  // console.log('fetch aa a a aa  aa aa a a a ', fetching);

  // useEffect(() => {
  //   if (added) {
  //     props.setOpen(false);
  //     dispatch(constructionActions.setAdded(false));
  //   }
  // }, [added, dispatch, props]);

  const handleFileInputChange = (e: any) => {
    // @ts-ignore: Object is possibly 'null'.
    const file = (e.target as HTMLInputElement).files[0];
    // previewFile(file);
    setSelectedFile(file);
    // setFileInputState(e.target.value);
  };

  // const previewFile = (file) => {
  //   const reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onloadend = () => {
  //     setPreviewSource(reader.result);
  //   };
  // };

  const handleSubmitFile = async (e: any) => {
    try {
      e.preventDefault();
      if (!selectedFile) return;
      let formdata = new FormData();
      formdata.append('file', selectedFile as File);
      if (nameRef.current?.value) formdata.append('name', nameRef.current?.value);
      if (type) formdata.append('type', type);
      formdata.append('project', project.id);
      setSaving(true);
      var response = await axios({
        method: 'POST',
        url: `${SERVER_URL}/api/v1/construction/document`,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formdata,
      });
      setSaving(false);
      console.log('and the ze response isssiss s  s s s ', response);
      props.setOpen(false);
      dispatch(projectActions.setUpdateNeeded(true));
    } catch (error: any) {
      console.log(error.response.data.message);
    }
    // const reader = new FileReader();
    // reader.readAsDataURL(selectedFile);
    // reader.onloadend = () => {
    //   uploadImage(reader.result);
    // };
    // reader.onerror = () => {
    //   console.error("AAAAH!");
    // };
  };

  const uploadImage = async (base64EncodedImage: any) => {
    let data: any = { image: base64EncodedImage };
    if (props.category) data.category = props.category;
    if (props.service) data.service = props.service;
    setUploading(true);
    try {
      const image = await axios({
        url: `${SERVER_URL}/api/v1/main/image`,
        method: 'POST',
        data,
      });
      if (props.service) {
        await axios({
          url: `${SERVER_URL}/api/v1/main/service/image-upload/${props.service}`,
          method: 'POST',
          data: {
            image: image.data.data.data._id,
          },
        });
      }
      setUploading(false);
      props.handleClose();
      dispatch(projectActions.setUpdateNeeded(true));
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={() => props.setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={`${styles.formHeader} ${styles.center}`}>
          <Typography className={styles.formHeaderText}>UPLOAD DOCUMENTS</Typography>
        </DialogTitle>
        <DialogContent className={styles.formContent}>
          <InputComponent label="Name" reference={nameRef} icon={<SearchIcon />} placeholder="Document name" />
          <SelectComponent label="Document Type" value={type} values={typeValues} handleChange={handleTypeChange} />
          <input
            type="file"
            id="avatar"
            onChange={handleFileInputChange}
            name="avatar"
            accept="image/*, application/msword, application/vnd.ms-excel, text/plain, application/pdf"
          />
          {/* <InputComponent
            label="Address"
            reference={locationRef}
            icon={<SearchIcon />}
            placeholder="Enter Office Location"
          /> */}
        </DialogContent>
        <DialogActions className={styles.center}>
          <Box className={styles.buttonContainer}>
            {!saving && (
              <Button onClick={handleSubmitFile} variant="contained" className={styles.formButton}>
                UPLOAD
              </Button>
            )}
            {saving && (
              <Button variant="contained" className={styles.formButton}>
                <CircularProgress size={30} className={styles.circularProgress} />
              </Button>
            )}
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}
