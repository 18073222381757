import { Select, MenuItem, Box, Typography } from '@mui/material';
import styles from '../../index.module.scss';
import { onSelectChanged } from './SelectComponent';

const FormSelect = (props: any) => {
  return (
    <Box className={styles.forms_inputComponent}>
      <Typography className={styles.label}>{props.label}</Typography>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        className={`${styles.form_input} ${styles.inputSelect}`}
        value={props.value}
        disableUnderline={true}
        onChange={(event: any) => onSelectChanged(event, props.setValue)}
      >
        {props?.values?.map((option: any) => (
          <MenuItem key={option?._id} value={option?._id}>
            {option[props.name]}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );
};

export default FormSelect;
