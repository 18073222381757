import { Grid, Container, Box, Divider, IconButton, InputBase } from '@mui/material';
import Badge from '@mui/material/Badge';
import SearchIcon from '@mui/icons-material/Search';
import MailIcon from '@mui/icons-material/Mail';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircle from '@mui/icons-material/AccountCircle';
import ShopLayout from '../../components/layout/Shop';
import styles from './index.module.scss';
import React from 'react';

const Shop = () => {
  const menuId = 'primary-search-account-menu';
  const mobileMenuId = 'primary-search-account-menu-mobile';
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <ShopLayout select={1}>
      <Box className={styles.shopHeader}>
        <div className={styles.search}>
          <div className={styles.searchIcon}>
            <SearchIcon />
          </div>
          <InputBase
            placeholder="Search…"
            classes={{
              root: styles.inputRoot,
              input: styles.inputInput,
            }}
            inputProps={{ 'aria-label': 'search' }}
          />
        </div>
        <div className={styles.sectionDesktop}>
          <IconButton aria-label="show 4 new mails" color="inherit">
            <Badge badgeContent={4} color="secondary">
              <MailIcon />
            </Badge>
          </IconButton>
          <IconButton aria-label="show 17 new notifications" color="inherit">
            <Badge badgeContent={17} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
        </div>
      </Box>
      <Divider />
      <Container>
        <Grid className={styles.statsCardContainer} container spacing={2}>
          <Grid item xs={3}>
            <Box className={styles.dashboardStatCard}>Purchases</Box>
          </Grid>
          <Grid item xs={3}>
            <Box className={styles.dashboardStatCard}>Sales</Box>
          </Grid>
          <Grid item xs={3}>
            <Box className={styles.dashboardStatCard}>Debts</Box>
          </Grid>
          <Grid item xs={3}>
            <Box className={styles.dashboardStatCard}>Credits</Box>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={9}>
            <Box className={styles.chartContainer}>
              <h3>Chart</h3>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box className={styles.chatContainer}>Chat</Box>
          </Grid>
        </Grid>
      </Container>
    </ShopLayout>
  );
};

export default Shop;
