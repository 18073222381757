import React, { useState, useEffect } from 'react';
import dayjs, { Dayjs } from 'dayjs';
// import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { Box, Grid, Paper, TextField, Stack, InputAdornment, MenuItem, Button } from '@mui/material';

const NewSale = () => {
  useEffect(() => {}, []);
  const [value, setValue] = React.useState<Dayjs | null>(dayjs('2014-08-18T21:11:54'));

  const handleChange = (newValue: Dayjs | null) => {
    setValue(newValue);
  };
  const man = ['Ben', 'Yamu', 'Eric'];

  return (
    <Grid container spacing={2}>
      <Grid item xs={8}>
        <Paper sx={{ padding: 2 }} elevation={3}>
          <Stack spacing={3} direction="column" alignItems={'center'} justifyContent="center">
            <h1> Product Form</h1>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Stack spacing={2}>
                  <TextField
                    fullWidth
                    size="small"
                    id="manufacturer"
                    label="Manufacturer"
                    variant="outlined"
                    select
                    helperText="Please select your Manufacturer"
                  >
                    {man.map((m) => (
                      <MenuItem key={m} value={m}>
                        {m}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField fullWidth size="small" id="quantity" label="Quantity" variant="outlined" type="number" />
                  <TextField
                    fullWidth
                    size="small"
                    id="price"
                    label="Selling Price"
                    variant="outlined"
                    type="number"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">Frw</InputAdornment>,
                    }}
                  />
                  <TextField
                    fullWidth
                    size="small"
                    id="payment"
                    label="Total Paid"
                    variant="outlined"
                    type="number"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">Frw</InputAdornment>,
                    }}
                  />
                  <TextField
                    fullWidth
                    size="small"
                    id="payment"
                    variant="outlined"
                    type="date"
                    helperText="Pick Date"
                  />
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Stack spacing={2}>
                  <TextField
                    fullWidth
                    size="small"
                    id="manufacturer"
                    label="Customer"
                    variant="outlined"
                    select
                    helperText="Please select your Manufacturer"
                  >
                    {man.map((m) => (
                      <MenuItem key={m} value={m}>
                        {m}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField fullWidth size="small" id="item" label="Item" variant="outlined" />
                  <TextField
                    fullWidth
                    size="small"
                    id="price"
                    label="Buying Price"
                    variant="outlined"
                    type="number"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">Frw</InputAdornment>,
                    }}
                  />

                  <TextField
                    fullWidth
                    size="small"
                    id="price"
                    label="Total Price"
                    variant="outlined"
                    type="number"
                    InputProps={{
                      startAdornment: <InputAdornment position="start">Frw</InputAdornment>,
                    }}
                  />

                  <TextField fullWidth size="small" id="payment" label="Sold By" variant="outlined" />
                </Stack>
              </Grid>
            </Grid>
            <Stack direction="row" spacing={2}>
              <Button sx={{ minWidth: '10rem' }} variant="contained">
                Add
              </Button>
              <Button sx={{ minWidth: '10rem' }} color="error" variant="contained">
                Cancel
              </Button>
            </Stack>
          </Stack>
        </Paper>
      </Grid>
      <Grid item xs={4}>
        <Paper elevation={3}>\</Paper>
      </Grid>
    </Grid>
  );
};

export default NewSale;
