import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Paper, Box, Typography } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import styles from '../../../pages/Home/index.module.scss';
import noData from '../../../assets/icons/no_data.svg';

export default function TableComponent(props: any) {
  const { rows, columns, count, page, rowsPerPage, handlePageChange, handleRowsPerPageChange, rowClickedHandler } =
    props;

  // const [page, setPage] = React.useState(0);
  // const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
  //   setPage(newPage);
  //   console.log(newPage);
  // };

  // const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  //   setRowsPerPage(parseInt(event.target.value, 10));
  //   setPage(0);
  // };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {rows?.length > 0 && (
        <>
          <TableContainer component={Paper}>
            <Table
              className={styles.table}
              classes={{ root: styles.customTable }}
              size="medium"
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell align={'left'}></TableCell>
                  {columns.map((column: any, index: number) => (
                    <TableCell key={index} align={column.align}>
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row: any, index: number) => (
                  <TableRow hover style={{ cursor: 'pointer', background: `${index % 2 == 0 ? '#f5faff' : 'white'}` }} key={index}>
                    <TableCell component="th" scope="row" align={'left'}>
                      {index + 1}
                    </TableCell>
                    {columns.map((column: any, index: number) => {
                      const value = row[column.id];
                      return (
                        <TableCell
                          key={index}
                          align={column.align}
                          onClick={() =>
                            column.id === 'action' ? null : rowClickedHandler(row.id, row.projectId, row)
                          }
                        >
                          {value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component="div"
            count={count}
            page={page}
            onPageChange={handlePageChange}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleRowsPerPageChange}
          />
        </>
      )}

      {rows.length === 0 && (
        <>
          <Box className={styles.noDataContainer}>
            <img src={noData} className={styles.noDataSvg} alt="no data" />
            <Typography>No Data</Typography>
          </Box>
        </>
      )}
    </>
  );
}
