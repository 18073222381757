import React, { useEffect, useState } from 'react';
import { Typography, Box } from '@mui/material';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { useSelector, useDispatch } from 'react-redux';
import HomeLayout from '../../../components/layout/HomeLayout';
import { getConsProjects } from '../../../store/construction/constructionActions';
import { projectActions } from '../../../store/projects';
import { useNavigate } from 'react-router-dom';
import NewProject from '../../../components/UI/Forms/NewProject';
import TopActions from '../../../components/layout/TopActions';
// import NewProject from "./NewProject";
import styles from '../index.module.scss';
import { constructionActions } from '../../../store/construction';
import Filter from '../../../components/UI/Forms/Filter';
import DashboardTable from './DashboardTable';
import store from 'store';
import { ChipData, LooseObject } from '../../../interfaces/constants';

const Home = (props: any) => {
  const navigate = useNavigate();
  const [newProject, setNewProject] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [chipData, setChipData] = useState([]);
  const [query, setQuery] = useState('');
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const constructionState = useSelector((state: any) => state.construction);
  const projectsState = useSelector((state: any) => state.project);
  const authToken = store.get('x-auth-token');

  const filterHandler = (filterData: any) => {
    setChipData(filterData);
  };

  useEffect(() => {
    let queryString = '';
    chipData.forEach((data: any) => {
      queryString += `&${data.id}=${data.filter}`;
    });
    setQuery(queryString);
    dispatch(projectActions.setUpdateNeeded(true));
  }, [chipData]);

  const handleDelete = (chipToDelete: ChipData) => () => {
    setChipData((chips) => chips.filter((chip: ChipData) => chip.key !== chipToDelete.key));
  };

  const createExportableData = (
    id: string,
    projectId: string,
    name: string,
    client: string,
    startDate: string,
    po: number,
    status: string,
    slug: string
  ): any => {
    return {
      name,
      client,
      startDate,
      po,
      status,
      slug,
    };
  };

  const data = constructionState?.projects?.map((project: any) =>
    createExportableData(
      project._id,
      project.project._id,
      project.project.name,
      project.client?.name,
      project.startDate.substring(0, 10),
      project.contractAmount,
      project.status,
      project.project.slug
    )
  );

  useEffect(() => {
    if (!authToken) {
      return navigate('/login');
    }
    if (projectsState.updateNeeded) {
      dispatch(projectActions.setIsFetching(true));
      dispatch(getConsProjects(`page=${page}&limit=${rowsPerPage}${query}`));
    }
  }, [dispatch, page, projectsState.updateNeeded, authToken, query]);

  return (
    <HomeLayout select={0}>
      <Box className={`${styles.center} ${styles.homeHeader}`}>
        <Typography className={styles.header}>CEMS LTD PROJECTS</Typography>
      </Box>
      <TopActions setFilter={setShowFilter} data={data} setNew={setNewProject} entity="Project" />
      {newProject && <NewProject open={newProject} setOpen={setNewProject} />}
      {showFilter && <Filter filterHandler={filterHandler} open={showFilter} setOpen={setShowFilter} />}
      {chipData.length > 0 && (
        <Stack direction="row" spacing={1} style={{ marginTop: '1rem', marginBottom: '1.5rem' }}>
          {chipData.map((data: any) => {
            const value = data?.value ? data.value : data.filter;
            return (
              <Chip
                key={data.key}
                label={`${data.label}: ${value}`}
                onDelete={data.label === 'React' ? undefined : handleDelete(data)}
              />
            );
          })}
        </Stack>
      )}
      <DashboardTable />
    </HomeLayout>
  );
};

export default Home;
