import React, { useState } from 'react';
import { Typography, Box, Menu, MenuItem, CircularProgress, Avatar } from '@mui/material';
import TableComponent from '../../../components/UI/others/TableComponent';
import users from './users.json';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import AddCardIcon from '@mui/icons-material/AddCard';
import RemoveIcon from '@mui/icons-material/Remove';
import ConfirmModal from '../../../components/UI/others/ConfirmModal';
import { usersColumns, userItem } from '../../../interfaces/projects';
import { deleteRed, primaryGreen, primaryColor } from '../../../assets/colors/colors';
import { useSelector, useDispatch } from 'react-redux';
import { ConstructionService } from '../../../store/construction/constructionService';
import { projectActions } from '../../../store/projects';
import styles from './index.module.scss';
import { constructionActions } from '../../../store/construction';
import { useNavigate } from 'react-router-dom';

const DashboardTable = () => {
  const [projectName, setProjectName] = useState('');
  const [projectId, setProjectId] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(25);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const constructionState = useSelector((state: any) => state.construction);
  const projectsState = useSelector((state: any) => state.project);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const closeProjectHandler = async () => {
    await ConstructionService.closeProject(projectId);
    setShowModal(false);
    dispatch(projectActions.setUpdateNeeded(true));
  };

  const handleCloseAction = () => {
    handleClose();
    setShowModal(true);
  };

  const handleDeleteAction = () => {
    setAnchorEl(null);
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const rowClickedHandler = (id: string, projectId: string, project: any) => {
    delete project.action;
    delete project.status;
    console.log('row clicked handlerrrrrr', project);
    dispatch(constructionActions.setSelectedProject(project));
    // navigate(`construction`);
  };

  const createData = (
    index: number,
    id: string,
    name: string,
    nid: string,
    phone: string,
    email: string,
    role: string,
    item: any,
    savingStatus?: any
  ): userItem => {
    return {
      index,
      id,
      name,
      nid,
      phone,
      email,
      role,
      item,
      savingStatus: (
        <>
          <Avatar
            style={{
              height: 10,
              width: 10,
              display: 'inline-block',
              marginRight: '5px',
              backgroundColor: savingStatus === 'draft' ? deleteRed : primaryGreen,
            }}
          >
            {' '}
          </Avatar>
          {savingStatus.charAt(0).toUpperCase() + savingStatus.slice(1)}
        </>
      ),
      action: (
        <button
          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
            setProjectName(name);
            setProjectId(id);
            handleClick(event);
          }}
          className={styles.actionsIcon}
        >
          •••
        </button>
      ),
    };
  };

  const rows = users.map((item: any, index: number) =>
    createData(index + 1, '11111111111', item.name, item.nid, item.phone, item.email, item.role, item, 'draft')
  );

  return (
    <>
      {!projectsState.isFetching && (
        <>
          <TableComponent
            rows={rows}
            columns={usersColumns}
            rowClickedHandler={rowClickedHandler}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
          />

          {open && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={handleCloseAction}>
                <AddIcon style={{ color: primaryColor }} />
                <Typography style={{ color: primaryColor, paddingLeft: '10px' }}> View Profile</Typography>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <RemoveIcon style={{ color: deleteRed }} />
                <Typography style={{ color: deleteRed, paddingLeft: '10px' }}> Block User</Typography>
              </MenuItem>
              <MenuItem onClick={handleCloseAction}>
                <AddCardIcon style={{ color: primaryColor }} />
                <Typography style={{ color: primaryColor, paddingLeft: '10px' }}>Reset Password</Typography>
              </MenuItem>
              <MenuItem onClick={handleClose}>
                <ModeEditIcon style={{ color: primaryColor }} />
                <Typography style={{ color: primaryColor, paddingLeft: '10px' }}>Update User</Typography>
              </MenuItem>
            </Menu>
          )}
          {showModal && (
            <ConfirmModal
              open={showModal}
              onCancel={() => setShowModal(false)}
              setOpen={setShowModal}
              onConfirm={closeProjectHandler}
              content={`Are you sure you want to close ${projectName} project`}
            />
          )}
        </>
      )}
      {projectsState.isFetching && (
        <Box className={styles.loadingContainer}>
          <CircularProgress />
        </Box>
      )}
    </>
  );
};

export default DashboardTable;
