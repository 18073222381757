import React, { useEffect, useState } from 'react';
import { Typography, Box, Menu, MenuItem, CircularProgress } from '@mui/material';
import HomeLayout from '../../components/layout/HomeLayout';
import { useDispatch, useSelector } from 'react-redux';
import TopActions from '../../components/layout/TopActions';
import NewPartner from '../../components/UI/Forms/NewClient';
import { getClients } from '../../store/construction/constructionActions';
import ClientsTable from '../../components/Tables/construction/partners';
import { projectActions } from '../../store/projects';
import { clientColumns, clientData } from '../../interfaces/clients';
import TableComponent from '../../components/UI/others/TableComponent';
import { FlashOnRounded } from '@mui/icons-material';
import styles from './index.module.scss';

const Clients = (props: any) => {
  const [isInit, setIsInit] = useState(true);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(50);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const dispatch = useDispatch();
  const [newPartner, setNewPartner] = useState(false);
  const [actionsModal, setActionsModal] = useState(false);
  const updateNeeded = useSelector((state: any) => state.project.updateNeeded);
  const fetching = useSelector((state: any) => state.project.isFetching);
  const clients = useSelector((state: any) => state.construction.clients);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePageChange = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createData = (
    id: string,
    name: string,
    email: string,
    contact: number,
    tinNumber: number,
    createdAt: string
  ): clientData => {
    return {
      id,
      name,
      email,
      contact,
      tinNumber,
      createdAt,
      action: (
        <button onClick={handleClick} className={styles.actionsIcon}>
          •••
        </button>
      ),
    };
  };

  const rows = clients?.map((client: any) =>
    createData(
      client.id,
      client.name,
      client.email ? client.email : 'Not Set',
      client.contact,
      client.tinNumber ? client.tinNumber : 'Not Set',
      client.createdAt.substring(0, 10)
    )
  );

  console.log('fetching... ', fetching);

  useEffect(() => {
    if (isInit || updateNeeded) {
      console.log('(isInit || updateNeeded) ', isInit, updateNeeded);
      dispatch(projectActions.setIsFetching(true));
      dispatch(getClients());
    }
    return () => {
      setIsInit(false);
    };
  }, [dispatch, isInit, updateNeeded]);

  return (
    <HomeLayout select={5}>
      {!fetching && (
        <>
          <Box className={`${styles.center} ${styles.homeHeader}`}>
            <Typography className={styles.header}>Clients</Typography>
          </Box>
          <TopActions setNew={setNewPartner} />
          {newPartner && <NewPartner open={newPartner} setOpen={setNewPartner} />}
          <TableComponent
            rows={rows}
            columns={clientColumns}
            count={count}
            page={page}
            rowsPerPage={rowsPerPage}
            handlePageChange={handlePageChange}
            handleRowsPerPageChange={handleRowsPerPageChange}
          />
          {open && (
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={handleClose}>View</MenuItem>
              <MenuItem onClick={handleClose}>Edit</MenuItem>
              <MenuItem onClick={handleClose}>Deactivate</MenuItem>
              <MenuItem onClick={handleClose}>Delete</MenuItem>
            </Menu>
          )}
        </>
      )}
      {fetching && (
        <Box className={styles.loadingContainer}>
          <CircularProgress />
        </Box>
      )}
    </HomeLayout>
  );
};

export default Clients;
