import { Container, Box, Divider, Button } from '@mui/material';
import ShopLayout from '../../components/layout/Shop';
import Header from '../../components/UI/others/Header';

const Payments = () => {
  return (
    <ShopLayout select={7}>
      <Header />
      <Divider />
      <Container>
        <Box>
          <Button variant="contained">Add New Item</Button>
        </Box>
      </Container>
    </ShopLayout>
  );
};

export default Payments;
