import React from 'react';
import { Button, DialogContentText, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import styles from '../../index.module.scss';
import deleteSvg from '../../../assets/images/delete.svg';

export default function ConfirmModal(props: any) {
  return (
    <div>
      <Dialog
        open={props.open}
        classes={{ paper: styles.confirmModal }}
        onClose={() => props.setOpen(false)}
        style={{ padding: '3rem' }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description" className={styles.vertical}>
            <img src={deleteSvg} alt="delete" style={{ height: '10rem', width: '10rem' }} />
            <Typography className={styles.confirmModal_title}>Confirm Delete</Typography>
            <Typography className={styles.confirmModal_text}>{props.content}</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={styles.vertical}>
          <Button onClick={() => props.onConfirm()} variant="contained" className={styles.confirmModal_Confirm}>
            Delete
          </Button>
          <Button onClick={() => props.onCancel()} className={styles.confirmModal_Cancel}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
